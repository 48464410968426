<template>
  <div>
    <v-container v-if="environment">
      <v-row>
        <v-col cols="6">
          <staging-upgrade-details :env="environment"></staging-upgrade-details>
        </v-col>
        <v-col cols="6">
          <staging-upgrade-history :env="environment"></staging-upgrade-history>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import StagingUpgradeHistory from "@/components/stagings/StagingUpgradeHistory"
import StagingUpgradeDetails from "@/components/stagings/StagingUpgradeDetails"
import {mapState} from "vuex";

export default {
  name: 'StagingDetails',
  components: {StagingUpgradeDetails, StagingUpgradeHistory},
  props: [
    'env'
  ],
  mounted() {
    this.$store.dispatch('stagings/fetchEnvDetails', this.env.name)
  },
  computed: {
    ...mapState('stagings', {
      'environment': state => state.environment
    })
  }
}
</script>
<style type="scss">

</style>
import axios from 'axios'
import store from '@/store'

const resource_uri = '/api/dynamic-envs'
const teams_uri = '/api/teams'

const state = {
  prevRecord: null,
  nextRecord: null,
  environments: [],
  ec2List: [],
  environment: null,
  projects: [],
  team: [],
}

const getters = {
  projects: state => state.projects,
  team: state => state.team,
  environment: state => state.environment,
  environments: state => state.environments,
  instances: (state) => (id) => {
    return state.ec2List.find(item => item.id === id)?.data;
  },
  hasInstances: (state) => (id) => {
    return state.ec2List.find(item => item.id === id)?.data.length > 0
  }
}

const actions = {
  async createEnv({dispatch}, payload) {
    let token = store.state.auth.token;

    return new Promise((resolve, reject) => {
      axios.post(resource_uri, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        // dispatch('fetchEnvDetails', payload.id).then(() => {
        dispatch('notifySuccess', { text: 'Environment has been created'}).then(() => {
          return resolve(response.data)
        })
        // })
      }, (error) => {
        return reject(error.response.data.errors)
      })
    })
  },
  async extendTTL({dispatch}, payload) {
    let token = store.state.auth.token;

    return new Promise((resolve, reject) => {
      axios.patch(`${resource_uri}/${payload.id}/extend-ttl`, {ttl: payload.ttl }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then(() => {
        dispatch('notifySuccess', { text: 'Environment TTL has been extended'}).then(() => {
          return resolve(true)
        })
        // })
      }, (error) => {
        return reject(error.response)
      })
    })
  },
  // eslint-disable-next-line
  terminate({commit, dispatch}, payload) {
    let token = store.state.auth.token;

    axios.post(resource_uri + "/" + payload.id + '/status', {
      status: "pending_termination",
      details: payload.reason
    },{
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(() => {
      dispatch('fetchEnvDetails', payload.id).then(() => {
        dispatch('notifySuccess', { text: 'Environment has been scheduled for termination'})
      })
    }, () => {
      dispatch('notifyError', {text: "Failed to update environment status"})
    });

    // commit('terminate', response)
  },

  async getProjects({commit}) {
    let token = store.state.auth.token;

    const response = await axios.get(teams_uri + "/projects", {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchProjects', response.data)
  },

  async getTeamForProject({commit}, projectName) {
    let token = store.state.auth.token;

    const response = await axios.get(teams_uri + "/projects/" + projectName, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchTeamForProjects', response.data)
  },

  async fetchEnvDetails({commit}, id) {
    let token = store.state.auth.token;

    const response = await axios.get(resource_uri + "/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchEnvDetails', response.data)
  },
  async fetchEnvironments({commit}, filters = {}) {
    let token = store.state.auth.token;

    const response = await axios.get(resource_uri, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: filters
    });

    commit('fetchEnvironments', response.data)
  },

  async fetchEc2Data({commit}, payload) {
    let token = store.state.auth.token;
    const response = await axios.get(`${resource_uri}/${payload.id}/ec2-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    commit('setEc2Data', {id: payload.id, data: response.data})
  },

  async runInstanceState({commit}, payload) {
    let token = store.state.auth.token;
    await axios.get(`${resource_uri}/${payload.id}/instance-state/${payload.action}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    commit('runInstanceState')
  },

  async runEc2State({commit},payload) {
    let token = store.state.auth.token;
    await axios.get(`${resource_uri}/${payload.id}/ec2-state/${payload.instanceid}/${payload.action}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    commit('runEc2State')
  },

  async runCheckEc2Status({commit}) {
    let token = store.state.auth.token;
    await axios.get(`${resource_uri}/check-ec2-status`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    commit('runCheckEc2Status')
  }
}

const mutations = {
  fetchProjects: (state, data) => {
    state.projects = data.projects
  },
  fetchTeamForProjects: (state, data) => {
    state.team = data.team
  },
  fetchEnvironments: (state, environments) => {
    state.environments = environments.items
    state.prevRecord = environments.prevRecord
    state.nextRecord = environments.nextRecord
  },
  fetchEnvDetails(state, environment) {
    state.environment = environment
  },
  setEc2Data: (state, data) => {
    const oldData = state.ec2List.filter((item) => { return item.id !== data.id })
    state.ec2List = [...oldData, data]
  },
  runInstanceState: () => {},
  runEc2State: () => {},
  runCheckEc2Status: () => {},
}

export default {
  state,
  getters,
  actions,
  mutations
}
<template>
  <div>
    <v-tabs v-model="currentItem" align-left flat >
      <v-tabs-slider></v-tabs-slider>

      <v-tab :key="'instance-list'">
        EC2 instances
      </v-tab>

      <v-tab :key="'details'">
        Details
        <v-tab-item>
          Details
        </v-tab-item>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentItem">
      <v-tab-item :key="'instance-list'">
        <env-ec2-instances :env="env"></env-ec2-instances>
      </v-tab-item>
      <v-tab-item :key="'details'">
        <v-container>
          <v-row>
            <v-col cols="6">
              <env-detail-additional-info :env="env"></env-detail-additional-info>
            </v-col>
            <v-col cols="6">
              <env-detail-history :env="env"></env-detail-history>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EnvDetailAdditionalInfo from "@/components/environments/EnvDetailAdditionalInfo";
import EnvDetailHistory from "@/components/environments/EnvDetailHistory";
import EnvEc2Instances from "@/components/environments/EnvEc2Instances";
export default {
  components: {EnvEc2Instances, EnvDetailHistory, EnvDetailAdditionalInfo},
  props: ['env'],
  data: () => {
    return {
      currentItem: 'instance-list',
    }
  },
}
</script>
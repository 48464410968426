<template>
  <v-row>
    <v-col class="info-label" cols="4">
      <p>{{ title }}</p>
    </v-col>
    <v-col class="info-details" cols="8">
      <slot/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: ['title']
}

</script>

<style lang="scss">

</style>
<template>
  <nav>
    <v-app-bar dark text>
      <v-app-bar-title class="text-uppercase grey--text mr-5">
        <router-link :to="{ path: '/' }" class="grey--text d-block">
          <span class="font-weight-light">Env</span>
          <span>Ctl</span>
        </router-link>
      </v-app-bar-title>

      <v-btn :to="{ path: '/dynamic-envs' }" color="blue" text>
        Dynamic Environments
      </v-btn>

      <v-btn :to="{ path: '/stagings' }" color="blue" text>
        Static Environments
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn :to="{ path: '/docs' }" color="blue" text>
        API Docs
      </v-btn>

      <v-btn v-if="$store.state.auth.authenticated"  color="grey" text @click="$store.dispatch('authLogout');">
        <span>Sign out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',

}
</script>

<style lang="scss">
a {
  text-decoration: none;
}
</style>

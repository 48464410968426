import { render, staticRenderFns } from "./swagger.vue?vue&type=template&id=9af59a1e&"
import script from "./swagger.vue?vue&type=script&lang=js&"
export * from "./swagger.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import * as types from './types'

export default {
  [types.AUTH]: state => {
    return state.auth
  },
  token: state => {
    return state.auth.token
  }
}

<template>
  <div class="staging-envs">
    <v-container class="my-5">
      <h2 class="mb-8 h2 grey--text">Static environments</h2>

      <v-container fluid>
        <v-row class="justify-space-between">
          <v-col cols="4">
            <v-text-field v-model="query.q" label="Search environments by domain" @keyup="search()"></v-text-field>
          </v-col>
          <v-col>
            <v-select
                v-model="query.status"
                :items="available_statuses"
                label="Filter by environment statuses"
                multiple
                @change="search()"
            >
              <template v-slot:selection="{ item }">
                <v-chip :class="`env-status ${item} white--text my-2 caption`" x-small>{{ item }}</v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
                v-model="query.projects"
                :items="$store.getters.projects"
                label="Filter by project name"
                @change="search()"
            ></v-select>
          </v-col>

        </v-row>
      </v-container>

      <v-expansion-panels>
        <v-expansion-panel v-for="env in $store.state.stagings.environments" :key="env.id"
                           :class="`mb-3 environment ${env.upgrade_status}`">
          <v-expansion-panel-header>
            <staging-env-heading :env="env"></staging-env-heading>
          </v-expansion-panel-header>
          <v-expansion-panel-content flat>
            <staging-env-details :env="env"></staging-env-details>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>

    <v-btn
        :bottom="true"
        :left="false"
        :right="true"
        :top="false"
        class="mr-7 mb-3 v-btn--fixed env-create-button"
        color="blue darken-2"
        dark
        fab
        large
        @click="createEnv">
      <v-icon>add</v-icon>
    </v-btn>

    <create-staging-env ref="createEnvDialog"></create-staging-env>

  </div>
</template>
<script>

import CreateStagingEnv from "@/components/stagings/CreateStagingEnv";
import StagingEnvHeading from "@/components/stagings/StagingEnvHeading";
import StagingEnvDetails from "@/components/stagings/StagingEnvDetails";
import getOrDefault from "@/utils/getOrDefault";
import formatDate from "@/utils/formatDate";
import {mapActions, mapState} from 'vuex'


export default {
  name: 'StagingEnvList',
  components: {CreateStagingEnv,StagingEnvHeading,StagingEnvDetails},
  methods: {
    ...mapActions({
      fetchEnvironments: 'stagings/fetchEnvironments'
    }),
    createEnv() {
      this.$refs.createEnvDialog.open().then(() => {
        this.fetchEnvironments(this.query)
      })
    },
    changePage(startRecord) {
      let query = Object.assign({}, this.query);
      query.start = startRecord;

      this.$router.push({query}).catch(() => {
      });
    },
    search() {
      let query = Object.assign({}, this.query);
      this.$router.push({query}).catch(() => {
      });
    },
    assignParams(params) {
      this.query = {
        start: params.start || '',
        limit: params.limit || 50,
        q: params.q || '',
        status: params.status || [],
        projects: params.projects || ''
      };
    },
  },
  data: () => {
    return {
      fab: false,
      currentItem: 'instance-list',
      available_statuses: [
        'upgrade_waiting', 'upgrading', 'upgrade_in_progress', 'upgrade_failed', 'upgraded'
      ]
    }
  },
  computed: {
    ...mapState({
      environments: state => state.stagings.environments,
    })
  },
  mounted() {
    this.fetchEnvironments(this.query)
  },
  created() {
    this.assignParams(this.$route.query)

    this.$watch(
        () => this.$route.query,
        (toParams) => {
          this.assignParams(toParams)
          this.fetchEnvironments(this.query)
        }
    )

    this.refreshInterval = setInterval(() => {
      this.fetchEnvironments(this.query)
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  filters: {
    getOrDefault: getOrDefault,
    formatDate: formatDate
  },
}
</script>
<style lang="scss">

.environment {
  &.upgraded {
    border-left: 4px solid #3a2;
  }
  &.upgrade_waiting {
    border-left: 4px solid #ffaa2c;
  }
  &.upgrade_failed {
    border-left: 4px solid #ff1500;
  }
  &.upgrade_in_progress {
    border-left: 4px solid #3cd1c2;
  }
}

.v-chip.env-status {
  &.upgraded {
    background: #3a2 !important;
  }

  &.upgrade_waiting {
    background: #ffaa2c !important;
  }

  &.upgrade_in_progress {
    background: #3cd1c2 !important;

    &.blink {
      -webkit-animation-name: blink;
      -webkit-animation-iteration-count: infinite;
      //-webkit-animation-timing-function: cubic-bezier(1.0, 0, 0, 1.0);
      -webkit-animation-duration: 2s;
    }
  }

  &.upgrade_failed {
    background: #ff1500 !important;
  }
}

.v-expansion-panel-content__wrap {
  padding: 24px 0 16px 0 !important;
}

.env-create-button {
  color: white;
  z-index: 999;
}

.v-divider {
  display: none;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1
  }
  5% {
    opacity: 0.9
  }
  10% {
    opacity: 0.8
  }
  15% {
    opacity: 0.7
  }
  20% {
    opacity: 0.6
  }
  25% {
    opacity: 0.5
  }
  30% {
    opacity: 0.4
  }
  35% {
    opacity: 0.3
  }
  40% {
    opacity: 0.2
  }
  45% {
    opacity: 0.1
  }
  50% {
    opacity: 0
  }
  55% {
    opacity: 0.1
  }
  60% {
    opacity: 0.2
  }
  65% {
    opacity: 0.3
  }
  70% {
    opacity: 0.4
  }
  75% {
    opacity: 0.5
  }
  80% {
    opacity: 0.6
  }
  85% {
    opacity: 0.7
  }
  90% {
    opacity: 0.8
  }
  95% {
    opacity: 0.9
  }
  100% {
    opacity: 1
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

</style>

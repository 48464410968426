export default (value, def) => {
  if (value === undefined || value === null) {
    return def
  }

  if (value === "") {
    return def
  }

  return value
}
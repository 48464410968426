<template>
  <v-container class="pa-0">
    <v-row class="col-12 pa-0" wrap>
      <v-col cols="12" sm="3">
        <div class="caption grey--text">Name</div>
        <div>
          <router-link v-if="isLinkEnabled" :to="{ name: 'staging-env-details', params: { id: env.id }}" class="body-1 black--text">{{ environment.display_name }} <v-icon>open_in_new</v-icon></router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="caption grey--text">Domain</div>
        <div>
          <p><span>{{ env.domain }}</span>
            <v-menu
                bottom
                dark
                icon
                open-on-hover
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="grey"
                    dark
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>open_in_new</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                  <v-list-item-title>
                    <v-btn :block="true"
                           :href="`https://${env.domain}/apps/administration/index.html#/`"
                           target="_blank">
                      Administration
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                  <v-list-item-title>
                    <v-btn :block="true"
                           :href="`https://${env.domain}/apps/cockpit/index.html#/`"
                           target="_blank">
                      Cockpit
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                  <v-list-item-title>
                    <v-btn :block="true"
                           :href="`https://${env.domain}/apps/devicemanagement/index.html`"
                           target="_blank">
                      Device management
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
      </v-col>
      <v-col cols="6" sm="2">
        <div class="caption grey--text">Version</div>
        <div>{{ environment.version }}</div>
      </v-col>
      <v-col cols="6" sm="2">
        <div class="caption grey--text">Project</div>
        <div>{{ environment.project_name }}</div>
      </v-col>
      <v-col cols="6" sm="2">
        <div class="right">
          <div class="caption grey--text">Status</div>
          <div>
            <v-chip :class="`env-status ${this.environment.upgrade_status} white--text caption blink`" small>{{ this.environment.upgrade_status }}</v-chip>
          </div>

        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-container>
</template>
<script>
import formatDate from "@/utils/formatDate";

export default {
  name: 'StagingEnvHeading',
  props: [
      'env', 'linkEnabled'
  ],
  data: () => {
    return {
      environment: {},
      isLinkEnabled: true,
    }
  },
  created() {
    this.isLinkEnabled = this.linkEnabled === undefined ? true : this.linkEnabled
    this.environment = this.env
  },
  filters: {
    formatDate: formatDate,
    lastStatusReason: (value) => {
      return value.at(-1).details
    }
  }
}
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}
</style>

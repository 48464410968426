const ADD = `ADD_TOAST_MESSAGE`
const REMOVE = `REMOVE_TOAST_MESSAGE`

export {
  ADD as ADD_TOAST_MESSAGE,
  REMOVE as REMOVE_TOAST_MESSAGE
}

let maxToastId = 0

const state = {
  messages: [],
}

const getters = {
  notifications: (state) => { return state.messages }
}

const actions = {
  notify ({ commit }, { text, type = 'info', dismissAfter = 1000 }) {
    const id = ++maxToastId

    commit(ADD, {id, text, type, dismissAfter})
    setTimeout(() => commit(REMOVE, id), dismissAfter)
  },

  notifySuccess({commit}, {text, dismissAfter = 1000}) {
    const id = ++maxToastId

    commit(ADD, {id, text, type: 'success', dismissAfter})
    setTimeout(() => commit(REMOVE, id), dismissAfter)

  },

  notifyError({commit}, {text, dismissAfter = 1000}) {
    const id = ++maxToastId

    commit(ADD, {id, text, type: 'error', dismissAfter})
    setTimeout(() => commit(REMOVE, id), dismissAfter)
  },

  notifyInfo({commit}, {text, dismissAfter = 1000}) {
    const id = ++maxToastId

    commit(ADD, {id, text, type: 'info', dismissAfter})
    setTimeout(() => commit(REMOVE, id), dismissAfter)
  },

  removeNotification ({ commit }, id) {
    commit(REMOVE, id)
  }
}

const mutations = {
  [ADD] (state, data) {
    state.messages.push(data)
  },

  [REMOVE] (state, id) {
    state.messages = state.messages.filter(m => m.id !== id)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
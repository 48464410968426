<template>
  <div>
    <h2 class="subtitle mt-5 mb-5 text-center">Additional information</h2>

    <v-container class="pl-5 pr-5">
      <detail-entry title="ID">
        <p>
          <span>{{ env.id }}</span> <router-link :to="{ name: 'dynamic-env-details', params: { id: env.id }}" class="body-1 black--text"><v-icon >open_in_new</v-icon></router-link>
        </p>
      </detail-entry>

      <detail-entry title="Start/stop environment ">
        <v-btn v-if="env.status == 'running'" class="mr-4" color="primary" small target="_blank" @click="stopEnv">Stop</v-btn>
        <v-btn v-if="env.status == 'stopped'" class="mr-4" color="primary" small target="_blank" @click="startEnv">Start</v-btn>
        <v-btn v-if="env.status !== 'stopped' &&  env.status !== 'running'" class="mr-4" color="primary" disabled small target="_blank">{{ env.status }}</v-btn>
      </detail-entry>

      <detail-entry title="Concourse jobs">
        <v-btn v-if="env.status !== 'pending'" :href="deploymentJobLink" class="mr-4" color="primary" small target="_blank">Deployment <v-icon>open_in_new</v-icon></v-btn>
        <v-btn v-if="env.status === 'terminating' || env.status === 'terminated' || env.status === 'termination_failed'" :href="cleanupJobLink" color="error" small target="_blank">Cleanup<v-icon>open_in_new</v-icon></v-btn>
      </detail-entry>

      <detail-entry title="URL">
        <p><span>{{ env.details.domain_data.domain }}</span>
          <v-menu
              bottom
              dark
              icon
              open-on-hover
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="grey"
                  dark
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>open_in_new</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/administration/index.html#/`"
                         target="_blank">
                    Administration
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/cockpit/index.html#/`"
                         target="_blank">
                    Cockpit
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/devicemanagement/index.html`"
                         target="_blank">
                    Device management
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/machine-learning/index.html#/home`"
                         target="_blank">
                    Machine learning
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="pb-0 pt-0 pr-0 pl-0">
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/machine-learning-workbench/index.html#/home`"
                         target="_blank">
                    Machine learning workbench
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/datahub-ui/index.html#/home`">
                    Datahub
                  </v-btn>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <v-btn :block="true"
                         :href="`http://management.${env.details.domain_data.domain}:8111/apps/streaminganalytics/index.html`">
                    Streaming Analytics
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </p>
      </detail-entry>

      <detail-entry title="Created at">
        <p>{{ env.created_at | formatDate }}</p>
      </detail-entry>

      <detail-entry title="Last transition date">
        <p>{{ env.last_transition_date | formatDate}}</p>
      </detail-entry>

      <detail-entry title="Version">
        <p>{{ env.details.version_data.version }}</p>
      </detail-entry>

      <detail-entry title="Cookbooks version">
        <p>{{ env.details.version_data.cookbooks_version }}</p>
      </detail-entry>

      <detail-entry :title="'Helm chart version'">
        <p>{{ env.details.version_data.chart_version }}</p>
      </detail-entry>

      <detail-entry :title="'LWM2M version'">
        <p>{{ env.details.version_data.lwm2m_version }}</p>
      </detail-entry>

      <detail-entry title="Deployed microservices">
        <p>{{ env.details.core_microservices }}</p>
      </detail-entry>

      <detail-entry title="Containerised ontoplb">
        <p>{{ env.details.containerized_ontoplb }}</p>
      </detail-entry>

      <detail-entry title="Enable microservices-registry">
        <p>{{ env.details.enable_microservices_registry }}</p>
      </detail-entry>

      <detail-entry title="Containerised actility">
        <p>{{ env.details.containerized_actility }}</p>
      </detail-entry>

      <detail-entry title="Standalone mongodb">
        <p>{{ env.details.standalone_mongodb }}</p>
      </detail-entry>

      <detail-entry :title="'Pulsar chart version'">
        <p v-if="env.details.pulsar_deploy">{{ env.details.pulsar_version }}</p>
        <p v-else><v-chip color="error" small>Pulsar is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Datahub version'">
        <p v-if="env.details.datahub_deploy">{{ env.details.datahub_version }}</p>
        <p v-else><v-chip color="error" small>Datahub is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry v-if="env.details.datahub_deploy" :title="'Dremio version'">
        <p>{{ env.details.dremio_version }}</p>
      </detail-entry>

      <detail-entry :title="'Apama version'">
        <p v-if="env.details.apama_deploy">{{ env.details.apama_version }}</p>
        <p v-else><v-chip color="error" small>Apama is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'CEP version'">
        <p v-if="env.details.cep_deploy">{{ env.details.cep_version }}</p>
        <p v-else><v-chip color="error" small>CEP is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Zementis version'">
        <p v-if="env.details.zementis_deploy">{{ env.details.zementis_version }}</p>
        <p v-else><v-chip color="error" small>Zementis is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Mlw version'">
        <p v-if="env.details.mlw_deploy">{{ env.details.mlw_version }}</p>
        <p v-else><v-chip color="error" small>Mlw is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Dtm version'">
        <p v-if="env.details.dtm_deploy">{{ env.details.dtm_version }}</p>
        <p v-else><v-chip color="error" small>Dtm is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of microservices'">
        <ul v-if="env.details.microservices_list">
          <li v-for="ms in env.details.microservices_list" :key="ms">
            {{ ms }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Microservices list is empty</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of webapps'">
        <ul v-if="env.details.webapps_list">
          <li v-for="webapp in env.details.webapps_list" :key="webapp">
            {{ webapp }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Webapps list is empty</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of helmcharts'">
        <ul v-if="env.details.helm_components">
          <li v-for="hc in env.details.helm_components" :key="hc">
            {{ hc }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Helm charts list is empty</v-chip></p>
      </detail-entry>

      <detail-entry title="yum repository">
        <p>{{ env.details.yum_repository }}</p>
      </detail-entry>

      <detail-entry title="image registry">
        <p>{{ env.details.registry_url }}/{{ env.details.registry_repo }}</p>
      </detail-entry>

      <detail-entry title="yum repository">
        <p>{{ env.details.yum_repository }}</p>
      </detail-entry>
    </v-container>

  </div>
</template>

<script>
import DetailEntry from "@/components/uikit/DetailEntry";
import formatDate from "@/utils/formatDate";

export default {
  props: ['env'],
  components: {DetailEntry},
  filters: {
    formatDate: formatDate
  },
  computed: {
    deploymentJobLink() {
      return `https://concourse.stage.c8y.io/teams/staging/pipelines/dynamic-envs?vars.domain=%22${this.env.details.domain_data.domain}%22&vars.id=%22${this.env.id}%22`
    },
    cleanupJobLink() {
      return `https://concourse.stage.c8y.io/teams/staging/pipelines/dynamic-envs-cleanup?vars.domain=%22${this.env.details.domain_data.domain}%22&vars.id=%22${this.env.id}%22`
    }
  },
  methods: {
    startEnv(){
      this.$store.dispatch('runInstanceState', {id: this.env.id, action: "start"})
      setTimeout(() => this.$store.dispatch('runCheckEc2Status'), 2000);
    },
    stopEnv(){
      this.$store.dispatch('runInstanceState', {id: this.env.id, action: "stop"})
      setTimeout(() => this.$store.dispatch('runCheckEc2Status'), 2000);
    }
  }
}

</script>

<style lang="scss">
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import DynamicEnvsList from '@/views/DynamicEnvsList.vue'
import store from '@/store'
import DocsView from "@/views/DocsView";
import DynamicEnvDetail from "@/views/DynamicEnvDetail";
import StagingEnvList from "@/views/StagingEnvList";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => {
      return { path: '/dynamic-envs' }
    }
  },
  {
    path: '/docs',
    name: 'docs',
    component: DocsView
  },
  {
    path: '/dynamic-envs',
    name: 'dynamic-envs',
    component: DynamicEnvsList
  },
  {
    path: '/dynamic-envs/:id',
    name: 'dynamic-env-details',
    component: DynamicEnvDetail
  },
  {
    path: '/stagings',
    name: 'stagings',
    component: StagingEnvList
  },
  {
    path: '/stagings/:id',
    name: 'staging-env-details',
    component: StagingEnvList
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  if (!store.state.auth.authenticated) {
    store.dispatch('authLogin')

    return next('/');
  }

  next();
})

export default router

<template>
  <div>
  <v-snackbar
      v-for="m in messages"
      :key="m.id"
      :color="m.type"
      :value="true"

      bottom
      dark
      outlined
      right
  >
    {{ m.text }}
  </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'NotificationToast',
  computed: {
    messages() {
      return this.$store.getters['notifications']
    },
  }
}
</script>

<style lang="scss">

</style>
<template>
  <v-app app class="grey lighten-4">
    <NavBar/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <NotificationToast/>
  </v-app>
</template>

<script>
import NavBar from '@/layout/Navbar'
import NotificationToast from "@/components/uikit/NotificationToast";

export default {
  name: 'App',

  components: {
    NotificationToast,
    NavBar

  },

  data: () => ({})
}
</script>
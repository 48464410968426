import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import VueClipboard from "vue-clipboard2"

Vue.config.productionTip = false

Vue.use(VueClipboard)

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: 'login-required'
  },
  config: {
    url: 'https://auth.dev.c8y.io/auth',
    clientId: 'envctl',
    realm: 'cumulocity'
  },
  onReady: (keycloak) => {
    Vue.$keycloak = keycloak
    console.log(Vue.$keycloak)
    if (keycloak.authenticated) {
      store.dispatch('authLogin')

      new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount('#app')
    }

    setInterval(() =>{
      keycloak.updateToken(70).then((refreshed)=>{
        if (refreshed) {
          store.dispatch('authLogin')
          localStorage.setItem("token", keycloak.token)
        } else {
          console.log('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(()=>{
        console.log('Failed to refresh token');
      });
    }, 60000)
  }
})
<template>
  <v-dialog v-model="show" max-width="600px" @keydown.esc="cancel">
    <!--    <v-btn v-slot="activator" class="success" flat>Add New Project</v-btn>-->
    <v-card>
      <v-card-title class="body-2 font-weight-bold" color="primary lighten-2 white--text" text>
        {{ confirmationTitle }}
      </v-card-title>

      <v-form ref="form" class="mb-5 mr-5 ml-5" @submit.prevent="agree">
        <v-card-text>
          <p>{{ confirmationMessage }}</p>

          <p>
            Please type <code>{{ messageToType}}</code> in order to proceed
          </p>
          <v-container>
            <v-row>
              <v-text-field
                    v-model="$v.type.$model"
                    :error-messages="errors"
                    label="Type"
                    @blur="$v.type.$touch"
                    @input="$v.type.$touch"
                ></v-text-field>
            </v-row>
          </v-container>

          <template v-if="options.askForValue">
            <v-container>
              <v-row>
                <v-text-field
                    v-model="$v.value.$model"
                    :error-messages="valueErrors"
                    :label="options.askForValueLabel"
                    @blur="$v.value.$touch"
                    @input="$v.value.$touch"
                ></v-text-field>
              </v-row>
            </v-container>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :disabled="$v.$invalid" :loading="loading" class="success mb-3 mt-3" text type="submit" @click.prevent="agree">Confirm</v-btn>
          <v-btn :loading="loading" class="info mb-3 mt-3" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { sameAs, required, requiredIf } from '@vuelidate/validators'

export default {
  name: "ConfirmDialogPopup",
  components: {},
  setup: () => ({ $v: useVuelidate() }),
  data: () => {
    return {
      loading: false,
      dialog: false,
      confirmationTitle: 'Please confirm your choice',
      confirmationMessage: 'Do you really want to proceed?',
      messageToType: 'confirm',
      options: {
        askForValue: false,
        askForValueLabel: "Please provide additional information"
      },
      resolve: null,
      reject: null,
      type: '',
      value: '',
      invalid: false,
    }
  },
  validations() {
    return {
      type: {
        required,
        invalid: sameAs(this.messageToType)
      },
      value: {
        requiredIf: requiredIf(this.options.askForValue)
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    },
    errors () {
      const errors = []

      if (!this.$v.type.$dirty) return errors

      if (this.$v.type.$invalid) {
        errors.push('Provided text does not match expected one')
      }

      return errors
    },
    valueErrors() {
      const errors = []

      if (!this.$v.value.$dirty) return errors

      if (this.$v.value.$invalid) {
        errors.push("This field is required")
      }

      return errors
    }
  },
  methods: {

    agree() {
      this.$v.$reset()
      this.type = ''
      this.dialog = false
      this.resolve({
        confirmed: true,
        value: this.value
      })
    },

    cancel() {
      this.$v.$reset()
      this.type = ''
      this.dialog = false
      this.resolve({
        confirmed: false
      })
    },

    open(title, message, options = {}) {
      this.dialog = true
      this.confirmationTitle = title
      this.confirmationMessage = message

      if (options !== undefined && options !== null) {
        this.options = Object.assign(this.options, options)
      }

      if (this.options.messageToType !== undefined) {
        this.messageToType = this.options.messageToType
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
  }
}
</script>

<style lang="scss">

</style>
<template>
  <div class="dynamic-envs">
    <v-container class="my-5">
      <h2 class="mb-8 h2 grey--text">Dynamic environments</h2>

      <v-container fluid>
        <v-row class="justify-space-between">
          <v-col>
            <v-text-field v-model="query.q" label="Search environments by domain" @keyup="search()"></v-text-field>
          </v-col>
          <v-col>
            <v-select
                v-model="query.status"
                :items="available_statuses"
                label="Filter by environment statuses"
                multiple
                @change="search()"
            >
              <template v-slot:selection="{ item }">
                <v-chip :class="`env-status ${item} white--text my-2 caption`" x-small>{{ item }}</v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
                v-model="query.projects"
                :items="$store.getters.projects"
                label="Filter by project name"
                @change="search()"
            ></v-select>
          </v-col>

        </v-row>
      </v-container>

      <v-expansion-panels>
        <v-expansion-panel v-for="env in $store.state.dynamic_envs.environments" :key="env.id"
                           :class="`mb-3 environment ${env.status}`">
          <v-expansion-panel-header>
            <env-detail-heading :env="env"></env-detail-heading>
          </v-expansion-panel-header>
          <v-expansion-panel-content flat>
            <env-detail :env="env"></env-detail>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-layout align-content-center class="mb-5 mt-5" justify-center row>
          <v-btn v-if="$store.state.dynamic_envs.prevRecord != null"
              color="primary"
               outlined
               @click="changePage($store.state.dynamic_envs.prevRecord)"
          >Previous page</v-btn>

          <div class="ml-3 mr-3"></div>

          <v-btn v-if="$store.state.dynamic_envs.nextRecord != null"
             color="primary"
             outlined
             @click="changePage($store.state.dynamic_envs.nextRecord)"
          >Next page</v-btn>

      </v-layout>

    </v-container>

    <v-btn
        :bottom="true"
        :left="false"
        :right="true"
        :top="false"
        class="mr-7 mb-3 v-btn--fixed env-create-button"
        color="blue darken-2"
        dark
        fab
        large
        @click="createEnv">
      <v-icon>add</v-icon>
    </v-btn>

    <create-env ref="createEnvDialog"></create-env>
  </div>

</template>

<script>
import formatDate from "@/utils/formatDate";
import getOrDefault from "@/utils/getOrDefault";

import EnvDetailHeading from "@/components/environments/EnvDetailHeading";
import EnvDetail from "@/components/environments/EnvDetail";
import CreateEnv from "@/components/environments/CreateEnv";

export default {
  name: 'DynamicEnvsList',
  components: {CreateEnv, EnvDetail, EnvDetailHeading},

  mounted() {
    this.$store.dispatch('fetchEnvironments', this.query)
    // dispatchEvent('fetchEnvironments')
  },
  created() {
    this.assignParams(this.$route.query)

    this.$watch(
        () => this.$route.query,
        (toParams) => {
          this.assignParams(toParams)
          this.$store.dispatch('fetchEnvironments', this.query)
        }
    )

    this.refreshInterval = setInterval(() => {
      this.$store.dispatch('fetchEnvironments', this.query)
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  data: () => {
    return {
      fab: false,
      currentItem: 'instance-list',
      available_statuses: [
          'pending', 'provisioning', 'provisioning_failed', 'provisioned', 'validation_failed',
          'running', 'pending_termination', 'terminating', 'terminated', 'termination_failed', 'stopped'
      ]
    }
  },
  methods: {
    changePage(startRecord) {
      let query = Object.assign({}, this.query);
      query.start = startRecord;

      this.$router.push({ query }).catch(()=>{});
    },
    search() {
      let query = Object.assign({}, this.query);
      this.$router.push({ query }).catch(()=>{});
    },
    assignParams(params) {
      this.query = {
        start: params.start || '',
        limit: params.limit || 50,
        q: params.q || '',
        status: params.status || [],
        projects: params.projects || ''
      };
    },
    createEnv() {
      this.$refs.createEnvDialog.open().then((result) => {
        console.log(result);
      })
    }
  },
  filters: {
    getOrDefault: getOrDefault,
    formatDate: formatDate
  },
}
</script>

<style lang="scss">
.environment {

  &.provisioned {
    border-left: 4px solid #2ca0ff;
  }

  &.running {
    border-left: 4px solid #3a2;
  }

  &.pending {
    border-left: 4px solid #ffaa2c;
  }

  &.provisioning {
    border-left: 4px solid #3cd1c2;
  }

  &.terminated, &.terminating, &.pending_termination {
    border-left: 4px solid #f83e70;
  }

  &.termination_failed, &.provisioning_failed, &.validation_failed {
    border-left: 4px solid #ff1500;
  }
}

.v-chip.env-status {
  &.running {
    background: #3a2 !important;
  }

  &.provisioned {
    background: #2ca0ff !important;
  }

  &.pending {
    background: #ffaa2c !important;
  }

  &.provisioning {
    background: #3cd1c2 !important;
  }

  &.unknown {
    background: #000000 !important;
  }

  &.starting {
    background: #690764 !important;
  }

  &.stopped {
    background: #555555 !important;
  }

   &.stopping {
    background: #555555 !important;
  }


  &.provisioning.blink, &.starting.blink, &.stopping.blink {
    -webkit-animation-name: blink;
    -webkit-animation-iteration-count: infinite;
    //-webkit-animation-timing-function: cubic-bezier(1.0, 0, 0, 1.0);
    -webkit-animation-duration: 2s;
  }

  &.terminated, &.terminating, &.pending_termination {
    background: #f64a58 !important;
  }

  &.termination_failed, &.provisioning_failed, &.validation_failed {
    background: #ff1500 !important;
  }
}

@-webkit-keyframes blink {
  0% { opacity: 1 }
  5% { opacity: 0.9 }
  10% { opacity: 0.8 }
  15% { opacity: 0.7 }
  20% { opacity: 0.6 }
  25% { opacity: 0.5 }
  30% { opacity: 0.4 }
  35% { opacity: 0.3 }
  40% { opacity: 0.2 }
  45% { opacity: 0.1 }
  50% { opacity: 0 }
  55% { opacity: 0.1 }
  60% { opacity: 0.2 }
  65% { opacity: 0.3 }
  70% { opacity: 0.4 }
  75% { opacity: 0.5 }
  80% { opacity: 0.6 }
  85% { opacity: 0.7 }
  90% { opacity: 0.8 }
  95% { opacity: 0.9 }
  100% { opacity: 1 }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.v-chip.instance-state {
  &.running {
    background: #3a2 !important;
  }

  &.stopped {
    background: #ffaa2c !important;
  }

  &.terminated {
    background: #ff1500 !important;
  }
}

.v-expansion-panel-content__wrap {
  padding: 24px 0 16px 0 !important;
}

.col {
  &.info-label {
    text-align: right;
    font-weight: bold;
    margin: 0;
    padding: 0;

    :after {
      content: ":";
      font-weight: bold;
    }
  }

  .info-details {
    margin: 0 0;
    padding: 0 20px;
  }
}

.env-create-button {
  z-index: 999;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import dynamic_envs from "@/store/modules/dynamic_envs"
import notification from "@/store/modules/notification"
import env_sizes from "@/store/modules/env_sizes"
import stagings from "@/store/modules/stagings"
import github from "@/store/modules/github"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    dynamic_envs: dynamic_envs,
    notification: notification,
    env_sizes: env_sizes,
    stagings: stagings,
    github: github
  }
})

import {helpers} from "@vuelidate/validators";
import isSemver from "is-semver";
import isEmpty from "lodash";

const validator = helpers.withMessage((params) => {
  console.log(params);
  return `${params.$model} is not valid semver`
}, (value) => {
  if (value === undefined || isEmpty(value.trim())) {
    return true
  }

  return isSemver(value)
})

export default validator
import * as types from './types'
import store from '../../index'
import Vue from 'vue'
export default {
  authLogin () {
    store.commit(types.AUTH, Vue.$keycloak)
  },
  authLogout () {
    Vue.$keycloak.logout()

    store.commit(types.AUTH, {
      authenticated: false
    })

    store.commit(types.AUTH)
  },
  init() {
    store.commit(types.AUTH, Vue.$keycloak)
  }
}

import * as types from './types'
// import Vue from 'vue'
import axios from 'axios'

export default {
  [types.AUTH] (state, keycloakAuth) {
    state.authenticated = keycloakAuth.authenticated
    state.token = keycloakAuth.token

    localStorage.setItem("access_token", state.token)

    axios.defaults.headers.common = { Authorization: 'Bearer ' + keycloakAuth.token }
    // Vue.prototype.$http.defaults.headers.common = { Authorization: 'Bearer ' + keycloakAuth.token }
  }
}

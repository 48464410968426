import {helpers} from "@vuelidate/validators";

const validSuffixes = [
  "dev.c8y.io", "stage.c8y.io", "performance.c8y.io"
]

const isFqdn = (value) => {
  if (!value) {
    return true
  }

  value = value.trim()

  if (value.length === 0) {
    return true
  }

  let re = /^(?![^-\n]*(?:-(?!\.)[^-\n]*)*-\.)(?:(?!-)[A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,63}$/g
  let suffixValid = false

  validSuffixes.forEach((suffix) => {
    if (value.endsWith(suffix)) {
      suffixValid = true
    }
  })

  return re.test(value) && suffixValid;
};

const message = (params) => {
  return `${params.$model} is not a valid fqdn`
}

export default helpers.withMessage(message, isFqdn)
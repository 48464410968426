<template>
  <v-dialog v-model="show" max-width="1000px" @keydown.esc="cancel()">
    <v-card>
      <v-card-title class="body-2 font-weight-bold" color="primary lighten-2 white--text" text>
        Extend TTL for dynamic env
      </v-card-title>

      <form ref="ttlForm" @submit.prevent="submit">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pa-0 pl-2 pr-2" cols="3">
                <v-autocomplete
                    v-model="$v.form.ttl.$model"
                    :error="hasErrors($v.form.ttl)"
                    :items="ttls"
                    :messages="getErrors('form.ttl', $v.form.ttl)"
                    item-text="display"
                    item-value="value"
                    label="TTL to extend"
                    @blur="$v.form.ttl.$touch()"
                    @input="$v.form.ttl.$touch()"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :loading="loading" class="success mb-3 mt-3" text type="submit">Extend</v-btn>
          <v-btn :loading="loading" class="info mb-3 mt-3" text @click.native="cancel()">Cancel</v-btn>
        </v-card-actions>

      </form>
    </v-card>

  </v-dialog>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {required} from '@vuelidate/validators';
import * as _ from "lodash"

export default {
  name: "DynamicEnvExtendTTL",
  props: ['env'],
  setup: () => ({$v: useVuelidate()}),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  data: () => ({
    ttls: [
      {display: '4 hours', value: 4},
      {display: '8 hours', value: 8},
      {display: '1 day', value: 24},
      {display: '2 days', value: 48},
      {display: '3 days', value: 72},
      {display: '5 days', value: 120},
      {display: '1 week', value: 168},
      {display: '2 weeks', value: 336},
      {display: '3 weeks', value: 504},
      {display: '4 weeks', value: 672},
    ],
    form: {
      ttl: 24,
    },
    dialog: false,
    loading: false,
    resolve: null,
    reject: null,
  }),
  validations() {
    return {
      form: {
        ttl: [required],
      }
    }
  },
  methods: {
    cancel() {
      this.dialog = false
      this.resolve({
        confirmed: false
      })
    },
    hasErrors(entity) {
      return this.getErrors(entity.$path, entity).length > 0;
    },
    getErrors(field, entity) {
      const errors = [];

      if (!entity.$dirty) {
        return errors
      }

      _.forEach(entity.$errors, (error) => {
        let message

        if (_.has(this.validationMessages, field) && _.has(this.validationMessages[field], error.$params.type)) {
          message = this.validationMessages[field][error.$params.type]
        } else {
          message = error.$message
        }

        errors.push(message);
      });

      if (_.has(this.validationErrors, field)) {
        _.forEach(this.validationErrors[field], (error) => {
          errors.push(error)
        })
      }

      return errors;

    },
    open() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$store.dispatch('notifyError', {
          text: 'There are invalid fields. Please resolve all issues and try again',
          dismissAfter: 5000
        })
        return
      }

      this.$store.dispatch('extendTTL', {id: this.env.id, ttl: this.form.ttl}).then(() => {
        this.dialog = false
        this.resolve(true)
      }, (errors) => {
        let message = 'There was an issue with extending environment TTL'
        if (errors.data.message) {
          message = errors.data.message
        }
        this.$store.dispatch('notifyError', {
          text: message,
          dismissAfter: 5000
        })

      });
    }
  }
}
</script>
<style lang="scss">

</style>
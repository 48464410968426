import axios from 'axios'
import store from '@/store'

const resource_uri = '/api/env-sizes'

const state = {
  sizes: [],
  selected_size: null
}

const getters = {
  selected_size: state => state.selected_size,
  sizes: state => state.sizes,

}

const actions = {
  // eslint-disable-next-line
  async fetchEnvSizes({commit}) {
    let token = store.state.auth.token;

    const response = await axios.get(resource_uri, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchEnvSizes', response.data)
  },
  selectSize({commit}, size) {
    commit('selectSize', size)
  }
}

const mutations = {
  fetchEnvSizes: (state, env_sizes) => {
    state.sizes = env_sizes
  },
  selectSize(state, size) {
    state.selected_size = size
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
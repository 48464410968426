import moment from "moment";

const formatDate = (value, format) => {
  if (typeof format === undefined) {
    format = 'lll'
  }

  if (value) {
    return moment(String(value)).format('lll')
  }

  return value
}

export default formatDate
<template>
  <div>
    <h2 class="subtitle mt-5 text-center">Upgrade history</h2>

    <v-timeline class="ml-5 mr-5">
      <v-timeline-item v-for="entry in env.version_data.status_history" :key="entry.occurred_at" right small>
        <template v-slot:opposite>
                    <span
                        :class="`font-weight-bold grey--text`" small
                    >{{ entry.occurred_at | formatDate('ll') }}</span>
        </template>
        <div class="py-4">
          <v-chip v-if="entry.target_status"
                  :class="`env-status ${entry.target_status} white--text my-2 caption`" small>
            {{ entry.target_status }}
          </v-chip>
          <br/>
          <small v-if="entry.details">{{ entry.details }}</small>
        </div>
      </v-timeline-item>
    </v-timeline>

  </div>
</template>

<script>
import {formatDate} from "@/utils"
export default {
  name: 'StagingUpgradeHistory',
  props: [
    'env'
  ],
  filters: {
    formatDate: formatDate
  }
}
</script>
<style type="scss">

</style>
<template>
  <v-dialog v-model="show" max-width="1000px" @keydown.esc="cancel()">

    <v-card>
      <v-card-title class="body-2 font-weight-bold" color="primary lighten-2 white--text" text>
        Create new static environment
      </v-card-title>
      <form ref="envForm" @submit.prevent="submit">
        <v-stepper v-model="formStepper" non-linear vertical>
          <v-stepper-step :complete="isStepCompleted($v.env)" :rules="[() => isStepValid($v.env)]" editable step="1">
            Environment details
            <small v-if="!isStepValid($v.env)">Please validate the form data</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="pa-0 pr-2">
                    <v-text-field
                        v-model="$v.env.display_name.$model"
                        :error="hasErrors($v.env.display_name)"
                        :messages="getErrors('display_name', $v.env.display_name)"
                        clearable
                        label="Display name"
                        @blur="$v.env.display_name.$touch()"
                        @input="$v.env.display_name.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 pl-2">
                    <v-text-field
                        v-model="$v.env.name.$model"
                        :error="hasErrors($v.env.name)"
                        :messages="getErrors('name', $v.env.name)"
                        :persistent-hint="true"
                        clearable
                        hint="Must be unique name"
                        label="Name"
                        @blur="$v.env.name.$touch()"
                        @input="$v.env.name.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0 pr-2" cols="6">
                    <v-text-field
                        v-model="$v.env.domain.$model"
                        :error="hasErrors($v.env.domain)"
                        :messages="getErrors('domain', $v.env.domain)"
                        :persistent-hint="true"
                        clearable
                        hint="Short form like latest.stage.c8y.io"
                        label="Environment domain name"
                        @blur="$v.env.domain.$touch()"
                        @input="$v.env.domain.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 pl-2" cols="6">
                    <v-text-field
                        v-model="$v.env.envs_path.$model"
                        :error="hasErrors($v.env.envs_path)"
                        :messages="getErrors('envs_path', $v.env.envs_path)"
                        clearable
                        label="Path within c8y_envs repository"
                        @blur="$v.env.envs_path.$touch()"
                        @input="$v.env.envs_path.$touch()"
                    ></v-text-field>

                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col class="pa-0 pr-2" cols="6">
                    <v-autocomplete
                        v-model="$v.env.project_name.$model"
                        :error="hasErrors($v.env.project_name)"
                        :items="$store.getters.projects"
                        :messages="getErrors('details.project_name', $v.env.project_name)"
                        label="Project name"
                        @blur="$v.env.project_name.$touch()"
                        @input="$v.env.project_name.$touch()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col class="pa-0 pr-2" cols="6">
                    <v-text-field
                        v-model="$store.getters.team"
                        label="Team name"
                        readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0 pr-2">
                    <v-text-field
                        v-model="$v.env.customer_tag_name.$model"
                        :error="hasErrors($v.env.customer_tag_name)"
                        :messages="getErrors('customer_tag_name', $v.env.customer_tag_name)"
                        :persistent-hint="true"
                        clearable
                        hint="AWS tag name to filter instances for particular static env"
                        label="Customer tag name"
                        @blur="$v.env.customer_tag_name.$touch()"
                        @input="$v.env.customer_tag_name.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 pl-2 pr-2">
                    <v-text-field
                        v-model="$v.env.customer_tag.$model"
                        :error="hasErrors($v.env.customer_tag)"
                        :messages="getErrors('customer_tag', $v.env.customer_tag)"
                        :persistent-hint="true"
                        clearable
                        hint="AWS tag value to search for"
                        label="Customer tag value"
                        @blur="$v.env.customer_tag.$touch()"
                        @input="$v.env.customer_tag.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 pl-2">
                    <v-text-field
                        v-model="$v.env.aws_region.$model"
                        :error="hasErrors($v.env.aws_region)"
                        :messages="getErrors('aws_region', $v.env.aws_region)"
                        :persistent-hint="true"
                        clearable
                        hint="AWS region where static is deployed"
                        label="AWS region"
                        @blur="$v.env.aws_region.$touch()"
                        @input="$v.env.aws_region.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-btn class="primary mb-3 mt-3 mr-3" @click="formStepper = 2">Next</v-btn>
            <v-btn :loading="loading" class="mb-3 mt-3" text @click.native="cancel()">Cancel</v-btn>

          </v-stepper-content>

          <v-stepper-step :complete="isStepCompleted($v.versionDetails)"
                          :rules="[() => isStepValid($v.versionDetails)]" editable step="2">
            Version details
            <small v-if="!isStepValid($v.versionDetails)">Please validate the form data</small>
          </v-stepper-step>
          <v-stepper-content editable step="2">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="pa-0">
                    <v-text-field
                        v-model="versionDetails.version"
                        :error="hasErrors($v.versionDetails.version) > 0"
                        :messages="getErrors('version', $v.versionDetails.version)"
                        clearable
                        label="Version"
                        @blur="$v.versionDetails.version.$touch()"
                        @input="$v.versionDetails.version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col class="pa-0 pr-2" cols="4">
                    <v-text-field
                        v-model="versionDetails.chart_version"
                        :error="hasErrors($v.versionDetails.chart_version) > 0"
                        :messages="getErrors('chart_version', $v.versionDetails.chart_version)"
                        clearable
                        label="Helm chart version"
                        @blur="$v.versionDetails.chart_version.$touch()"
                        @input="$v.versionDetails.chart_version.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="pa-0 pl-2" cols="4">
                    <v-text-field
                        v-model="versionDetails.cookbooks_version"
                        :error="hasErrors($v.versionDetails.cookbooks_version) > 0"
                        :messages="getErrors('cookbooks_version', $v.versionDetails.cookbooks_version)"
                        clearable
                        label="Cookbooks version"
                        @blur="$v.versionDetails.cookbooks_version.$touch()"
                        @input="$v.versionDetails.cookbooks_version.$touch()"
                    ></v-text-field>
                  </v-col>
                    <v-col class="pa-0 pl-2" cols="4">
                    <v-text-field
                        v-model="versionDetails.lwm2m_version"
                        :error="hasErrors($v.versionDetails.lwm2m_version) > 0"
                        :messages="getErrors('lwm2m_version', $v.versionDetails.lwm2m_version)"
                        clearable
                        label="Lwm2m version"
                        @blur="$v.versionDetails.lwm2m_version.$touch()"
                        @input="$v.versionDetails.lwm2m_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.pulsar_deploy"
                                :label="'Do you want to deploy pulsar?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.pulsar_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.pulsar_version"
                        :error="hasErrors($v.versionDetails.pulsar_version) > 0"
                        :messages="getErrors('pulsar_version', $v.versionDetails.pulsar_version)"
                        clearable
                        label="Pulsar version"
                        @blur="$v.versionDetails.pulsar_version.$touch()"
                        @input="$v.versionDetails.pulsar_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.datahub_deploy"
                                :label="'Do you want to deploy CDH?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.datahub_deploy" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.datahub_version"
                        :error="hasErrors($v.versionDetails.datahub_version) > 0"
                        :messages="getErrors('datahub_version', $v.versionDetails.datahub_version)"
                        clearable
                        label="Datahub version"
                        @blur="$v.versionDetails.datahub_version.$touch()"
                        @input="$v.versionDetails.datahub_version.$touch()"
                    ></v-text-field>

                  </v-col>

                  <v-col v-if="versionDetails.datahub_deploy" class="pa-0 pl-2" cols="4">
                    <v-text-field
                        v-model="versionDetails.dremio_version"
                        :error="hasErrors($v.versionDetails.dremio_version) > 0"
                        :messages="getErrors('dremio_version', $v.versionDetails.dremio_version)"
                        clearable
                        label="Dremio version"
                        @blur="$v.versionDetails.dremio_version.$touch()"
                        @input="$v.versionDetails.dremio_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.apama_deploy"
                                :label="'Do you want to deploy apama?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.apama_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.apama_version"
                        :error="hasErrors($v.versionDetails.apama_version) > 0"
                        :messages="getErrors('apama_version', $v.versionDetails.apama_version)"
                        clearable
                        label="Apama version"
                        @blur="$v.versionDetails.apama_version.$touch()"
                        @input="$v.versionDetails.apama_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.cep_deploy" :label="'Do you want to deploy CEP?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.cep_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.cep_version"
                        :error="hasErrors($v.versionDetails.cep_version) > 0"
                        :messages="getErrors('cep_version', $v.versionDetails.cep_version)"
                        clearable
                        label="CEP version"
                        @blur="$v.versionDetails.cep_version.$touch()"
                        @input="$v.versionDetails.cep_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.zementis_deploy"
                                :label="'Do you want to deploy zementis?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.zementis_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.zementis_version"
                        :error="hasErrors($v.versionDetails.zementis_version) > 0"
                        :messages="getErrors('zementis_version', $v.versionDetails.zementis_version)"
                        clearable
                        label="Zementis version"
                        @blur="$v.versionDetails.zementis_version.$touch()"
                        @input="$v.versionDetails.zementis_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.mlw_deploy" :label="'Do you want to deploy mlw?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.mlw_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.mlw_version"
                        :error="hasErrors($v.versionDetails.mlw_version) > 0"
                        :messages="getErrors('mlw_version', $v.versionDetails.mlw_version)"
                        clearable
                        label="Mlw version"
                        @blur="$v.versionDetails.mlw_version.$touch()"
                        @input="$v.versionDetails.mlw_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.dtm_deploy" :label="'Do you want to deploy dtm?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.dtm_deploy" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.dtm_version"
                        :error="hasErrors($v.versionDetails.dtm_version) > 0"
                        :messages="getErrors('dtm_version', $v.versionDetails.dtm_version)"
                        clearable
                        label="Dtm version"
                        @blur="$v.versionDetails.dtm_version.$touch()"
                        @input="$v.versionDetails.dtm_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0">
                    <v-text-field
                        v-model="versionDetails.microservices_list"
                        :error="hasErrors($v.versionDetails.microservices_list) > 0"
                        :messages="getErrors('microservices_list', $v.versionDetails.microservices_list)"
                        :persistent-hint="true"
                        clearable
                        hint="Comma separated list of microservices(Without space) with full path after /var/www/staging-resources/. Example: sag/cep/<version>/kubernetes-images/cep.zip for layeredproducts and kubernetes-images/report-agent-<version>.zip for common microservices"
                        label="List of microservices"
                        @blur="$v.versionDetails.microservices_list.$touch()"
                        @input="$v.versionDetails.microservices_list.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0">
                    <v-text-field
                        v-model="versionDetails.webapps_list"
                        :error="hasErrors($v.versionDetails.webapps_list) > 0"
                        :messages="getErrors('webapps_list', $v.versionDetails.webapps_list)"
                        :persistent-hint="true"
                        clearable
                        hint="Comma separated list of webapps(Without space) with full path after /var/www/staging-resources/. Example: sag/apama/10.15.0.0.20220822-0305-066bd2624/webapps/streaming-analytics-app.zip,sag/zementis/10.15.0.0.26/webapps/machine-learning.zip"
                        label="List of webapps"
                        @blur="$v.versionDetails.webapps_list.$touch()"
                        @input="$v.versionDetails.webapps_list.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0">
                    <v-text-field
                        v-model="versionDetails.helmchart_list"
                        :error="hasErrors($v.versionDetails.helmchart_list) > 0"
                        :messages="getErrors('helmchart_list', $v.versionDetails.helmchart_list)"
                        :persistent-hint="true"
                        clearable
                        hint="Comma separated list of helmcharts(Without space) with details like <chart-repo>/<chart-name>:<chart-version>. Example: stack/ssl-management:1018.113.0,stack/cumulocity-ontoplb:1019.4.0"
                        label="List of helmcharts"
                        @blur="$v.versionDetails.helmchart_list.$touch()"
                        @input="$v.versionDetails.helmchart_list.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.apama_pvt" :label="'Do you want to add apama pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.apama_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.apama_pvt_repo"
                        :error="hasErrors($v.versionDetails.apama_pvt_repo) > 0"
                        :messages="getErrors('apama_pvt_repo', $v.versionDetails.apama_pvt_repo)"
                        clearable
                        label="Apama PVT image"
                        @blur="$v.versionDetails.apama_pvt_repo.$touch()"
                        @input="$v.versionDetails.apama_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.apama_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.apama_pvt_version"
                        :error="hasErrors($v.versionDetails.apama_pvt_version) > 0"
                        :messages="getErrors('apama_pvt_version', $v.versionDetails.apama_pvt_version)"
                        clearable
                        label="Apama PVT tag"
                        @blur="$v.versionDetails.apama_pvt_version.$touch()"
                        @input="$v.versionDetails.apama_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.e2e_pvt" :label="'Do you want to add e2e pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.e2e_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.e2e_pvt_repo"
                        :error="hasErrors($v.versionDetails.e2e_pvt_repo) > 0"
                        :messages="getErrors('e2e_pvt_repo', $v.versionDetails.e2e_pvt_repo)"
                        clearable
                        label="E2e PVT image"
                        @blur="$v.versionDetails.e2e_pvt_repo.$touch()"
                        @input="$v.versionDetails.e2e_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.e2e_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.e2e_pvt_version"
                        :error="hasErrors($v.versionDetails.e2e_pvt_version) > 0"
                        :messages="getErrors('e2e_pvt_version', $v.versionDetails.e2e_pvt_version)"
                        clearable
                        label="E2e PVT tag"
                        @blur="$v.versionDetails.e2e_pvt_version.$touch()"
                        @input="$v.versionDetails.e2e_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.datahub_pvt" :label="'Do you want to add datahub pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.datahub_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.datahub_pvt_repo"
                        :error="hasErrors($v.versionDetails.datahub_pvt_repo) > 0"
                        :messages="getErrors('datahub_pvt_repo', $v.versionDetails.datahub_pvt_repo)"
                        clearable
                        label="Datahub PVT image"
                        @blur="$v.versionDetails.datahub_pvt_repo.$touch()"
                        @input="$v.versionDetails.datahub_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.datahub_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.datahub_pvt_version"
                        :error="hasErrors($v.versionDetails.datahub_pvt_version) > 0"
                        :messages="getErrors('datahub_pvt_version', $v.versionDetails.datahub_pvt_version)"
                        clearable
                        label="Datahub PVT tag"
                        @blur="$v.versionDetails.datahub_pvt_version.$touch()"
                        @input="$v.versionDetails.datahub_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.mlw_pvt" :label="'Do you want to add mlw pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.mlw_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.mlw_pvt_repo"
                        :error="hasErrors($v.versionDetails.mlw_pvt_repo) > 0"
                        :messages="getErrors('mlw_pvt_repo', $v.versionDetails.mlw_pvt_repo)"
                        clearable
                        label="Mlw PVT image"
                        @blur="$v.versionDetails.mlw_pvt_repo.$touch()"
                        @input="$v.versionDetails.mlw_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.mlw_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.mlw_pvt_version"
                        :error="hasErrors($v.versionDetails.mlw_pvt_version) > 0"
                        :messages="getErrors('mlw_pvt_version', $v.versionDetails.mlw_pvt_version)"
                        clearable
                        label="Mlw PVT tag"
                        @blur="$v.versionDetails.mlw_pvt_version.$touch()"
                        @input="$v.versionDetails.mlw_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.zementis_pvt" :label="'Do you want to add zementis pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.zementis_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.zementis_pvt_repo"
                        :error="hasErrors($v.versionDetails.zementis_pvt_repo) > 0"
                        :messages="getErrors('zementis_pvt_repo', $v.versionDetails.zementis_pvt_repo)"
                        clearable
                        label="Zementis PVT image"
                        @blur="$v.versionDetails.zementis_pvt_repo.$touch()"
                        @input="$v.versionDetails.zementis_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.zementis_pvt" class="pa-0" cols="4">
                    <v-text-field
                        v-model="versionDetails.zementis_pvt_version"
                        :error="hasErrors($v.versionDetails.zementis_pvt_version) > 0"
                        :messages="getErrors('zementis_pvt_version', $v.versionDetails.zementis_pvt_version)"
                        clearable
                        label="Zementis PVT tag"
                        @blur="$v.versionDetails.zementis_pvt_version.$touch()"
                        @input="$v.versionDetails.zementis_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="space-between">
                  <v-col class="pa-0" cols="4">
                    <v-checkbox v-model="versionDetails.ops_pvt" :label="'Do you want to add ops pvt?'"></v-checkbox>
                  </v-col>
                  <v-col v-if="versionDetails.ops_pvt" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.ops_pvt_repo"
                        :error="hasErrors($v.versionDetails.ops_pvt_repo) > 0"
                        :messages="getErrors('ops_pvt_repo', $v.versionDetails.ops_pvt_repo)"
                        clearable
                        label="Ops PVT image"
                        @blur="$v.versionDetails.ops_pvt_repo.$touch()"
                        @input="$v.versionDetails.ops_pvt_repo.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="versionDetails.ops_pvt" class="pa-0" cols="8">
                    <v-text-field
                        v-model="versionDetails.ops_pvt_version"
                        :error="hasErrors($v.versionDetails.ops_pvt_version) > 0"
                        :messages="getErrors('ops_pvt_version', $v.versionDetails.ops_pvt_version)"
                        clearable
                        label="Ops PVT tag"
                        @blur="$v.versionDetails.ops_pvt_version.$touch()"
                        @input="$v.versionDetails.ops_pvt_version.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-btn class="primary mb-3 mt-3 mr-3" @click="formStepper = 3">Next</v-btn>
            <v-btn class="warning mb-3 mt-3 mr-3" @click="formStepper = 1">Previous</v-btn>
            <v-btn :loading="loading" class="mb-3 mt-3" text @click.native="cancel()">Cancel</v-btn>

          </v-stepper-content>

          <v-stepper-step :rules="[() => isStepValid($v.branchDetails)]" step="3">
            Branching details
            <small v-if="!isStepValid($v.branchDetails)">Please validate the form data</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-autocomplete
                        v-model="branchDetails.envs_branch"
                        :error="hasErrors($v.branchDetails.envs_branch) > 0"
                        :items="c8y_envs_branches"
                        :messages="getErrors('envs_branch', $v.branchDetails.envs_branch)"
                        item-text="name"
                        item-value="name"
                        label="c8y_envs repository branch"
                        @blur="$v.branchDetails.envs_branch.$touch()"
                        @input="$v.branchDetails.envs_branch.$touch()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                        v-model="branchDetails.provisioners_branch"
                        :error="hasErrors($v.branchDetails.provisioners_branch) > 0"
                        :items="c8y_provisioners_branches"
                        :messages="getErrors('provisioners_branch', $v.branchDetails.provisioners_branch)"
                        item-text="name"
                        item-value="name"
                        label="c8y_provisioners repository branch"
                        @blur="$v.branchDetails.provisioners_branch.$touch()"
                        @input="$v.branchDetails.provisioners_branch.$touch()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                        v-model="branchDetails.concourse_branch"
                        :error="hasErrors($v.branchDetails.concourse_branch) > 0"
                        :items="c8y_concourse_branches"
                        :messages="getErrors('concourse_branch', $v.branchDetails.concourse_branch)"
                        item-text="name"
                        item-value="name"
                        label="c8y_concourse repository branch"
                        @blur="$v.branchDetails.concourse_branch.$touch()"
                        @input="$v.branchDetails.concourse_branch.$touch()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-btn :disabled="$v.$invalid" :loading="loading" class="success mr-2 mb-3 mt-3" text type="submit">Create</v-btn>
            <v-btn class="warning mb-3 mt-3 mr-3" @click="formStepper = 2">Previous</v-btn>
            <v-btn :loading="loading" class="mb-3 mt-3" text @click.native="cancel()">Cancel</v-btn>

          </v-stepper-content>

        </v-stepper>

        <v-card-actions>
          <v-spacer></v-spacer>


        </v-card-actions>

      </form>

    </v-card>
  </v-dialog>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import * as _ from "lodash"
import {required, requiredIf} from '@vuelidate/validators';
import {mapActions, mapGetters} from "vuex"
import {isFqdn,isValidSemver} from '@/utils';

const trimSpaces = (val) => {
  return val.toLowerCase().replaceAll(/\s+/g, "-")
}

export default {
  name: 'CreateStagingEnv',
  components: {},
  setup: () => ({$v: useVuelidate()}),

  computed: {
    ...mapGetters({
      branchesFor: 'github/branches'
    }),
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  data: () => ({
    c8y_envs_branches: [],
    c8y_provisioners_branches: [],
    c8y_concourse_branches: [],
    formStepper: 1,
    env: {
      "name": "",
      "display_name": "",
      "domain": "",
      "envs_path": "",
      "project_name": "",
      "team_name": "",
      "customer_tag": "",
      "customer_tag_name": "Customer",
      "aws_region": "eu-central-1",
    },
    branchDetails: {
      "provisioners_branch": "master",
      "concourse_branch": "master",
      "envs_branch": "staging",
    },
    versionDetails: {
      "version": "",
      "chart_version": "",
      "cookbooks_version": "",
      "lwm2m_version": "",

      "pulsar_deploy": false,
      "pulsar_version": "1014.1.0",

      "datahub_deploy": false,
      "datahub_version": "10.15.0.1.97",
      "dremio_version": "10.15.0.0.18",

      "apama_deploy": false,
      "apama_version": "10.15.0.0.20220822-0305-066bd2624",

      "zementis_deploy": false,
      "zementis_version": "10.15.0.0.26",

      "mlw_deploy": false,
      "mlw_version": "10.15.0.0.165",

      "cep_deploy": false,
      "cep_version": "10.15.0.124",

      "dtm_deploy": false,
      "dtm_version": "1015.0.0.272",

      "microservices_list": "",
      "webapps_list": "",
      "helmchart_list": [],

      "apama_pvt": false,
      "apama_pvt_repo": "registry.stage.c8y.io:443/pvt/pvt-paq",
      "apama_pvt_version": "10.15.0.4.20221125-1214-17a91cce4",

      "e2e_pvt": false,
      "e2e_pvt_repo": "registry.stage.c8y.io:443/pvt/cumulocity-e2e",
      "e2e_pvt_version": "r1015.0.0-SNAPSHOT",

      "datahub_pvt": false,
      "datahub_pvt_repo": "registry.stage.c8y.io:443/pvt/datahub",
      "datahub_pvt_version": "10.15.0.3.150",

      "mlw_pvt": false,
      "mlw_pvt_repo": "registry.stage.c8y.io:443/pvt/mlw",
      "mlw_pvt_version": "10.15.0.0.165",

      "zementis_pvt": false,
      "zementis_pvt_repo": "registry.stage.c8y.io:443/pvt/zementis",
      "zementis_pvt_version": "10.15.0.1.28",

      "ops_pvt": false,
      "ops_pvt_repo": "registry.stage.c8y.io:443/pvt/ovt",
      "ops_pvt_version": "latest",
    },
    dialog: false,
    loading: false,
    resolve: null,
    reject: null,
    validationMessages: {}
  }),
  mounted() {
    this.$store.dispatch('getProjects')
    this.$store.dispatch('getTeamForProject')
    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_envs'}).then(() => {
      this.c8y_envs_branches = this.branchesFor("Cumulocity-IoT/c8y_envs")
    });

    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_concourse'}).then(() => {
      this.c8y_concourse_branches = this.branchesFor("Cumulocity-IoT/c8y_concourse")
    })
    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_provisioners'}).then(() => {
      this.c8y_provisioners_branches = this.branchesFor("Cumulocity-IoT/c8y_provisioners")
    })
  },
  watch: {
    'versionDetails.version': {
      handler(newVersion, oldVersion) {
        if (this.versionDetails.cookbooks_version === oldVersion || _.isEmpty(this.versionDetails.cookbooks_version)) {
          this.versionDetails.cookbooks_version = newVersion
          this.$v.versionDetails.cookbooks_version.$touch();
        }

        if (this.versionDetails.chart_version === oldVersion || _.isEmpty(this.versionDetails.chart_version)) {
          this.versionDetails.chart_version = newVersion
          this.$v.versionDetails.chart_version.$touch();
        }
        if (this.versionDetails.lwm2m_version === oldVersion || _.isEmpty(this.versionDetails.lwm2m_version)) {
          this.versionDetails.lwm2m_version = newVersion
          this.$v.versionDetails.lwm2m_version.$touch();
        }
      }
    },
    'env.display_name': {
      handler(newVal, prevVal) {
        if (_.isEmpty(this.env.name) || trimSpaces(prevVal) === this.env.name) {
          this.env.name = trimSpaces(newVal);
          this.$v.env.name.$touch();
        }

        if (_.isEmpty(this.env.envs_path) || trimSpaces(prevVal) === this.env.envs_path) {
          this.env.envs_path = trimSpaces(newVal);
          this.$v.env.envs_path.$touch();
        }

        if (_.isEmpty(this.env.customer_tag) || trimSpaces(prevVal) === this.env.customer_tag) {
          this.env.customer_tag = trimSpaces(newVal);
          this.$v.env.customer_tag.$touch();
        }
      }
    },
    'env.project_name': {
      handler(newVal) {
        this.$store.dispatch('getTeamForProject', newVal)
      }
    },
  },
  methods: {
    ...mapActions({
      createEnv: 'stagings/createEnv',
      createUpgrade: 'stagings/createVersion',
      notifyError: 'notifyError',
      fetchBranches: 'github/fetchBranches'
    }),
    isStepValid(form) {
      return !(form.$invalid && form.$dirty)
    },
    isStepCompleted(form) {
      return !form.$invalid
    },
    cancel() {
      this.dialog = false
      this.resolve({
        confirmed: false
      })
    },
    hasErrors(entity) {
      let path = entity.$path.replace('env.', '');

      return this.getErrors(path, entity).length > 0;
    },
    getErrors(field, entity) {
      const errors = [];

      if (!entity.$dirty) {
        return errors
      }

      _.forEach(entity.$errors, (error) => {
        let message

        if (_.has(this.validationMessages, field) && _.has(this.validationMessages[field], error.$params.type)) {
          message = this.validationMessages[field][error.$params.type]
        } else {
          message = error.$message
        }

        errors.push(message);
      });

      if (_.has(this.validationErrors, field)) {
        _.forEach(this.validationErrors[field], (error) => {
          errors.push(error)
        })
      }

      return errors;

    },
    open() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.notifyError({
          text: 'There are invalid fields. Please resolve all issues and try again',
          dismissAfter: 5000
        })
        return
      }

      this.createEnv(this.env).then((result) => {
        this.validationErrors = []
        this.env.id = result.id

        const details = this.versionDetails
        details.microservices_list = this.versionDetails.microservices_list.split(',')
        details.webapps_list = this.versionDetails.webapps_list.split(',')

        if (!_.isEmpty(this.versionDetails.helmchart_list) && this.versionDetails.helmchart_list.split(',').length > 0) {
          let helmchartList = this.versionDetails.helmchart_list.split(',')
          let len = helmchartList.length
          details.helmchart_list = []

          for (let i = 0; i < len; i++) {
            let firstpart = helmchartList[i].split('/')
            let chartRepo = firstpart[0]
            let secondpart = firstpart[1].split(':')
            let chartName = secondpart[0]
            let chartVersion = secondpart[1]

            details.helmchart_list.push({
              chart_repository: chartRepo,
              chart_name: chartName,
              chart_version: chartVersion
            })
          }
        }
        this.createUpgrade({
          envName: this.env.name,
          notify: false,
          payload: {
            branch_details: this.branchDetails,
            version_details: details
          }}
        ).then((result) => {
          console.log(result);
          this.dialog = false
          this.resolve(this.env)
        }, (errors) => {
          this.validationErrors = {}
          _.forEach(errors, (error) => {
            this.validationErrors[error.json_field] = error.messages
          })

          this.notifyError({
            text: 'There are invalid fields. Please resolve all issues and try again',
            dismissAfter: 5000
          })
        })
      }, (errors) => {
        this.validationErrors = {}
        _.forEach(errors, (error) => {
          this.validationErrors[error.json_field] = error.messages
        })

        this.notifyError({
          text: 'There are invalid fields. Please resolve all issues and try again',
          dismissAfter: 5000
        })
      });
    }
  },
  validate() {
    this.$v.$touch();
  },
  validations() {
    return {
      env: {
        display_name: [required],
        name: [required],
        envs_path: [required],
        project_name: [required],
        domain: [required, isFqdn],
        customer_tag: [required],
        customer_tag_name: [required],
        aws_region: [required],
      },
      branchDetails: {
        envs_branch: [required],
        provisioners_branch: [required],
        concourse_branch: [required],
      },
      versionDetails: {
        apama_version: [requiredIf(this.versionDetails.apama_deploy)],
        pulsar_version: [requiredIf(this.versionDetails.pulsar_deploy), isValidSemver],
        zementis_version: [requiredIf(this.versionDetails.zementis_deploy)],
        mlw_version: [requiredIf(this.versionDetails.mlw_deploy)],
        cep_version: [requiredIf(this.versionDetails.cep_deploy)],
        dtm_version: [requiredIf(this.versionDetails.dtm_deploy)],

        datahub_version: [requiredIf(this.versionDetails.datahub_deploy)],
        dremio_version: [requiredIf(this.versionDetails.datahub_deploy)],

        version: [required, isValidSemver],
        chart_version: [required, isValidSemver],
        cookbooks_version: [required, isValidSemver],
        lwm2m_version: [required, isValidSemver],
        microservices_list: [],
        webapps_list: [],
        helmchart_list: [],

        apama_pvt_repo: [requiredIf(this.versionDetails.apama_pvt)],
        apama_pvt_version: [requiredIf(this.versionDetails.apama_pvt)],
        e2e_pvt_repo: [requiredIf(this.versionDetails.e2e_pvt)],
        e2e_pvt_version: [requiredIf(this.versionDetails.e2e_pvt)],
        datahub_pvt_repo: [requiredIf(this.versionDetails.datahub_pvt)],
        datahub_pvt_version: [requiredIf(this.versionDetails.datahub_pvt)],
        mlw_pvt_repo: [requiredIf(this.versionDetails.mlw_pvt)],
        mlw_pvt_version: [requiredIf(this.versionDetails.mlw_pvt)],
        zementis_pvt_repo: [requiredIf(this.versionDetails.zementis_pvt)],
        zementis_pvt_version: [requiredIf(this.versionDetails.zementis_pvt)],
        ops_pvt_repo: [requiredIf(this.versionDetails.ops_pvt)],
        ops_pvt_version: [requiredIf(this.versionDetails.ops_pvt)],
      }
    }
  },
}
</script>
<style lang="scss">
@import 'floating-vue/dist/style.css';
</style>

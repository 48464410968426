<template>
  <v-layout class="pa-4" row wrap>
    <v-flex md4 xs8>
      <div class="caption grey--text">Domain</div>
      <div>
        <router-link v-if="isLinkEnabled" :to="{ name: 'dynamic-env-details', params: { id: env.id }}" class="body-1 black--text">{{ env.details.domain_data.domain }} <v-icon>open_in_new</v-icon></router-link>
        <p v-else class="body-1 black--text">{{ env.details.domain_data.domain }}</p>
      </div>
    </v-flex>
    <v-flex md2 sm4 xs6>
      <div class="caption grey--text">Owner</div>
      <div>{{ env.owner }}</div>
    </v-flex>
    <v-flex md2 sm4 xs6>
      <div class="caption grey--text">Project</div>
      <div>{{ env.project_name }}</div>
    </v-flex>
    <v-flex md2 sm4 xs6>
      <div class="caption grey--text">Due by</div>
      <div v-if="env.ttl > 0 && !isLocked(env)">
        {{ env.terminate_at | formatDate }}
      </div>
      <div v-if="env.ttl > 0 && isLocked(env)">
        <v-chip color="warning" small>Environment is locked</v-chip>
      </div>
      <div v-if="env.ttl == 0"><v-chip color="error" small>No TTL specified</v-chip></div>
    </v-flex>
    <v-flex md2 sm4 xs2>
      <div class="right">
        <div class="caption grey--text">Status</div>
        <div>
          <v-chip :class="`env-status ${env.status} white--text caption blink`" small>{{ env.status }}</v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
            ><v-icon v-if="env.status === 'provisioning_failed'" class="ml-1" color="error">info</v-icon></span>
            </template>
            <span>{{ env.history | lastStatusReason}}</span>
          </v-tooltip>
        </div>

      </div>
    </v-flex>
    <v-divider></v-divider>
  </v-layout>
</template>

<script>
import formatDate from "@/utils/formatDate";

export default {
  props: ['env', 'linkEnabled'],
  created() {
    this.isLinkEnabled = this.linkEnabled === undefined ? true : this.linkEnabled
  },
  data: () => {
    return {
      isLinkEnabled: false
    }
  },
  methods: {
    isLocked(env) {
      return env.lock != null && env.lock.is_locked;
    },
  },
  filters: {
    formatDate: formatDate,
    lastStatusReason: (value) => {
      return value.at(-1).details
    }
  }
}
</script>

<style lang="scss">
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}
</style>

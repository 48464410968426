<template>
  <div>
    <h2 class="subtitle mt-5 mb-5 text-center">Additional information</h2>
    <v-container class="pl-5 pr-5">
      <detail-entry title="Concourse upgrade job">
        <p>
          <v-btn :href="concourseJobLink" color="error" small target="_blank">Upgrade job<v-icon>open_in_new</v-icon></v-btn>
        </p>
      </detail-entry>

      <detail-entry title="Mark upgrade as successful">
        <v-btn class="mr-4" color="primary" small target="_blank" @click="markUpgradeSuccessful">Mark upgrade as successful</v-btn>
      </detail-entry>

      <detail-entry title="Requested at">
        <p>{{ env.version_data.occurred_at | formatDate('ll') }}</p>
      </detail-entry>

      <detail-entry title="Version">
        <p>{{ env.version_data.details.version }}</p>
      </detail-entry>

      <detail-entry title="Helm chart version">
        <p>{{ env.version_data.details.chart_version }}</p>
      </detail-entry>

      <detail-entry title="Cookbooks version">
        <p>{{ env.version_data.details.cookbooks_version }}</p>
      </detail-entry>
      
      <detail-entry title="Lwm2m version">
        <p>{{ env.version_data.details.lwm2m_version }}</p>
      </detail-entry>

      <detail-entry :title="'Pulsar chart version'">
        <p v-if="env.version_data.details.pulsar_deploy">{{ env.version_data.details.pulsar_version }}</p>
        <p v-else><v-chip color="error" small>Pulsar is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Datahub version'">
        <p v-if="env.version_data.details.datahub_deploy">{{ env.version_data.details.datahub_version }}</p>
        <p v-else><v-chip color="error" small>Datahub is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry v-if="env.version_data.details.datahub_deploy" :title="'Dremio version'">
        <p>{{ env.version_data.details.dremio_version }}</p>
      </detail-entry>

      <detail-entry :title="'Apama version'">
        <p v-if="env.version_data.details.apama_deploy">{{ env.version_data.details.apama_version }}</p>
        <p v-else><v-chip color="error" small>Apama is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'CEP version'">
        <p v-if="env.version_data.details.cep_deploy">{{ env.version_data.details.cep_version }}</p>
        <p v-else><v-chip color="error" small>CEP is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Zementis version'">
        <p v-if="env.version_data.details.zementis_deploy">{{ env.version_data.details.zementis_version }}</p>
        <p v-else><v-chip color="error" small>Zementis is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Mlw version'">
        <p v-if="env.version_data.details.mlw_deploy">{{ env.version_data.details.mlw_version }}</p>
        <p v-else><v-chip color="error" small>Mlw is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Dtm version'">
        <p v-if="env.version_data.details.dtm_deploy">{{ env.version_data.details.dtm_version }}</p>
        <p v-else><v-chip color="error" small>Dtm is not deployed</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of webapps'">
        <ul v-if="env.version_data.details.webapps_list">
          <li v-for="webapp in env.version_data.details.webapps_list" :key="webapp">
            {{ webapp }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Product webapps list is empty</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of microservices'">
        <ul v-if="env.version_data.details.microservices_list">
          <li v-for="ms in env.version_data.details.microservices_list" :key="ms">
            {{ ms }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Product microservices list is empty</v-chip></p>
      </detail-entry>

      <detail-entry :title="'List of helmcharts'">
        <ul v-if="env.version_data.details.helmchart_list">
          <li v-for="hc in env.version_data.details.helmchart_list" :key="hc">
            {{ hc }}
          </li>
        </ul>
        <p v-else><v-chip color="error" small>Product helmchart list is empty</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Apama PVT image'">
        <p v-if="env.version_data.details.apama_pvt">{{ env.version_data.details.apama_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>Apama PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'Apama PVT tag'">
        <p v-if="env.version_data.details.apama_pvt">{{ env.version_data.details.apama_pvt_version }}</p>
        <p v-else><v-chip color="error" small>Apama PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry :title="'E2E PVT image'">
        <p v-if="env.version_data.details.e2e_pvt">{{ env.version_data.details.e2e_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>E2e PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'E2E PVT tag'">
        <p v-if="env.version_data.details.e2e_pvt">{{ env.version_data.details.e2e_pvt_version }}</p>
        <p v-else><v-chip color="error" small>E2e PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Datahub PVT image'">
        <p v-if="env.version_data.details.datahub_pvt">{{ env.version_data.details.datahub_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>Datahub PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'Datahub PVT tag'">
        <p v-if="env.version_data.details.datahub_pvt">{{ env.version_data.details.datahub_pvt_version }}</p>
        <p v-else><v-chip color="error" small>Datahub PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Mlw PVT image'">
        <p v-if="env.version_data.details.mlw_pvt">{{ env.version_data.details.mlw_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>Mlw PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'Mlw PVT tag'">
        <p v-if="env.version_data.details.mlw_pvt">{{ env.version_data.details.mlw_pvt_version }}</p>
        <p v-else><v-chip color="error" small>Mlw PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Zementis PVT image'">
        <p v-if="env.version_data.details.zementis_pvt">{{ env.version_data.details.zementis_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>Zementis PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'Zementis PVT tag'">
        <p v-if="env.version_data.details.zementis_pvt">{{ env.version_data.details.zementis_pvt_version }}</p>
        <p v-else><v-chip color="error" small>Zementis PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry :title="'Ops PVT image'">
        <p v-if="env.version_data.details.ops_pvt">{{ env.version_data.details.ops_pvt_repo }}</p>
        <p v-else><v-chip color="error" small>Ops PVT image is not present</v-chip></p>
      </detail-entry>
      <detail-entry :title="'Ops PVT tag'">
        <p v-if="env.version_data.details.ops_pvt">{{ env.version_data.details.ops_pvt_version }}</p>
        <p v-else><v-chip color="error" small>Ops PVT tag is not present</v-chip></p>
      </detail-entry>

      <detail-entry title="c8y_envs branch">
        <p>{{ env.version_data.branch_details.envs_branch }}</p>
      </detail-entry>

      <detail-entry title="c8y_provisioners branch">
        <p>{{ env.version_data.branch_details.provisioners_branch }}</p>
      </detail-entry>

      <detail-entry title="c8y_concourse branch">
        <p>{{ env.version_data.branch_details.concourse_branch }}</p>
      </detail-entry>
    </v-container>
  </div>
</template>
<script>
import DetailEntry from "@/components/uikit/DetailEntry";
import formatDate from "@/utils/formatDate";

export default {
  name: 'StagingUpgradeDetails',
  props: [
    'env'
  ],
  components: {DetailEntry},
  filters: {
    formatDate: formatDate
  },
  computed: {
    concourseJobLink() {
      return `https://concourse.stage.c8y.io/teams/staging/pipelines/${this.env.name}?vars.pipeline=%22upgrade%22`
    }
  },
  methods: {
    markUpgradeSuccessful(){
      this.$store.dispatch('stagings/markUpgradeSuccesful', {id: this.env.current_version, name: this.env.name })
    }
  }
}
</script>
<style type="scss">
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}
</style>
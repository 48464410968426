<template>
  <div class="container">
    <div class="row">
      <v-tabs class="docs" vertical>
          <v-tab class="v-list-item__title" to="#swagger"> Swagger docs</v-tab>
          <v-tab-item id="swagger" key="swagger">
            <SwaggerDocs></SwaggerDocs>
          </v-tab-item>

          <v-tab class="v-list-item__title" to="#authentication">Authentication</v-tab>
          <v-tab-item id="authentication" key="authentication">
            <markdown-it-vue :content="docs.authentication" class="md-body" />
          </v-tab-item>

          <v-tab class="v-list-item__title" to="#dynamic-env-creation">Dynamic env creation</v-tab>
          <v-tab-item id="dynamic-env-creation" key="dynamic-env-creation">
            <markdown-it-vue :content="docs.dynamicEnvCreation" class="md-body" />
          </v-tab-item>

          <v-tab class="v-list-item__title" to="#dynamic-env-extending-ttl">Extending TTL for environment</v-tab>
          <v-tab-item id="dynamic-env-extending-ttl" key="dynamic-env-extending-ttl">
            <markdown-it-vue :content="docs.extendingEnvTtl" class="md-body" />
          </v-tab-item>

        <v-tab class="v-list-item__title" to="#dynamic-env-locking">Preventing environment termination</v-tab>
        <v-tab-item id="dynamic-env-locking" key="dynamic-env-locking">
          <markdown-it-vue :content="docs.lockingEnvironment" class="md-body" />
        </v-tab-item>

        <v-tab class="v-list-item__title" to="#dynamic-env-stopping">Running environment 24/7</v-tab>
        <v-tab-item id="dynamic-env-stopping" key="dynamic-env-stopping">
          <markdown-it-vue :content="docs.stoppingEnvironment" class="md-body" />
        </v-tab-item>

        <v-tab class="v-list-item__title" to="#dynamic-env-terminating">Terminating environment</v-tab>
        <v-tab-item id="dynamic-env-terminating" key="dynamic-env-terminating">
          <markdown-it-vue :content="docs.terminatingEnvironment" class="md-body" />
        </v-tab-item>
      </v-tabs>
    </div>

  </div>
</template>

<script>


// import VueMarkdown from 'vue-markdown-render'
import SwaggerDocs from '@/docs/swagger'
import MarkdownItVue from "markdown-it-vue";

import authenticationMd from "../../../../docs/readmes/authentication.md"
import dynamicEnvCreationMd from "../../../../docs/readmes/dynamic-env-creation.md"
import extendingEnvTtlMd from "../../../../docs/readmes/dynamic-env-extending-ttl.md"
import lockingEnvironmentMd from "../../../../docs/readmes/locking-environment.md"
import stoppingEnvironmentMd from "../../../../docs/readmes/running-environment-without-stopping.md"
import terminatingEnvironmentMd from "../../../../docs/readmes/terminating-environment.md"

// import "highlight.js/styles/a11y-dark.css"
import "highlight.js/styles/monokai-sublime.css"

export default {
  name: 'DocsView',
  components: {
    // VueMarkdown,
    SwaggerDocs,
    MarkdownItVue
  },
  data: () => {
    return {
      tab: "#swagger"
    }
  },
  computed: {
    docs: () => {
      return {
        authentication: authenticationMd,
        dynamicEnvCreation: dynamicEnvCreationMd,
        extendingEnvTtl: extendingEnvTtlMd,
        lockingEnvironment: lockingEnvironmentMd,
        stoppingEnvironment: stoppingEnvironmentMd,
        terminatingEnvironment: terminatingEnvironmentMd,
      }
    },
  }
}
</script>

<style lang="scss">

.information-container.wrapper {
  display: none;
}

.docs {
  margin-top: 1rem;

  .v-list-item__title {
    text-transform: none;
  }

  .v-tabs-bar {
    padding: 1.5rem;

    a {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    margin-right: 1rem;
  }

  .v-tabs-items {
    padding: 1.5rem 0;
  }

  a.v-list-item__title {
    display: inline-block;
    line-height: 48px;
    width: 100%;
    text-align: left;
  }

  ol {
    li {
      padding-bottom: 20px;
    }
  }
}

h1 {
  padding-bottom: 1.5rem;
}

h2, h3 {
  padding-bottom: 1rem;
}

//pre {
//  border: 1px solid rgba(0, 0, 0, 0.4);
//}

.v-application pre {
  //padding-top: 1.5rem;
  //padding-bottom: 1.5rem;
  margin-bottom: 1rem;

  code {
    padding: 1.5rem;
    display: inline-block;
    width: 100vw;
  }
}

</style>
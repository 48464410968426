import axios from 'axios'
import store from '@/store'

const resource_uri = '/api/stagings'
const teams_uri = '/api/teams'

const state = {
  prevRecord: null,
  nextRecord: null,
  environments: [],
  ec2List: [],
  environment: null,
  projects: [],
  team: [],
}

const getters = {
  projects: state => state.projects,
  team: state => state.team,
  environment: state => state.environment,
  environments: state => state.environments,
  instances: (state) => (id) => {
    return state.ec2List.find(item => item.id === id)?.data;
  },
  hasInstances: (state) => (id) => {
    return state.ec2List.find(item => item.id === id)?.data.length > 0
  }
}

const actions = {
  async createEnv({dispatch}, payload) {
    let token = store.state.auth.token;

    return new Promise((resolve, reject) => {
      axios.post(resource_uri, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        // dispatch('fetchEnvDetails', payload.id).then(() => {
        dispatch('notifySuccess', { text: 'Environment has been created'}, {root: true}).then(() => {
          return resolve(response.data)
        })
        // })
      }, (error) => {
        return reject(error.response.data.errors)
      })
    })
  },

  async createVersion({dispatch}, data) {
    let token = store.state.auth.token;

    console.log(data)

    return new Promise((resolve, reject) => {

      axios.post(`${resource_uri}/${data.envName}/upgrades`, data.payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        if (!data.notify) {
          return resolve(response.data)
        }
        dispatch('notifySuccess', { text: 'Ugprade request has been created'}, {root: true}).then(() => {
          return resolve(response.data)
        })
      }, (error) => {
        return reject(error.response.data.errors)
      })
    })
  },

  async fetchEnvDetails({commit}, id) {
    let token = store.state.auth.token;

    const response = await axios.get(resource_uri + "/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchEnvDetails', response.data)
  },

  async fetchEnvironments({commit}, filters = {}) {
    let token = store.state.auth.token;

    const response = await axios.get(resource_uri, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: filters
    });

    commit('fetchEnvironments', response.data)
  },

  async fetchInstances({commit}, payload) {
    let token = store.state.auth.token;

    const response = await axios.get(`${resource_uri}/${payload.id}/instances`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    commit('setInstances', {id: payload.id, data: response.data})
  },

  async markUpgradeSuccessful({commit}, payload) {
    let token = store.state.auth.token;

    const response = await axios.patch(resource_uri + "/" + payload.name + "/upgrades/" + payload.id + "/status", {
      status: "upgraded"
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('markUpgradeSuccessful', response.data)
  },

  async getProjects({commit}) {
    let token = store.state.auth.token;

    const response = await axios.get(teams_uri + "/projects", {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchProjects', response.data)
  },

  async getTeamForProject({commit}, projectName) {
    let token = store.state.auth.token;

    const response = await axios.get(teams_uri + "/projects/" + projectName, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })

    commit('fetchTeamForProjects', response.data)
  },

}

const mutations = {
  fetchProjects: (state, data) => {
    state.projects = data.projects
  },
  fetchTeamForProjects: (state, data) => {
    state.team = data.team
  },
  fetchEnvironments: (state, environments) => {
    state.environments = environments.items
    state.prevRecord = environments.prevRecord
    state.nextRecord = environments.nextRecord
  },
  fetchEnvDetails(state, environment) {
    state.environment = environment
  },
  setInstances: (state, data) => {
    const oldData = state.ec2List.filter((item) => { return item.id !== data.id })
    state.ec2List = [...oldData, data]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

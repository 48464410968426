<template>
  <div id="docs"></div>
</template>

<script>
import {SwaggerUIBundle, SwaggerUIStandalonePreset} from "swagger-ui-dist"
import 'swagger-ui-dist/swagger-ui.css';

export default {
  name: 'SwaggerDocs',
  mounted() {
    const spec = require('../../../../docs/definition.yaml');

    SwaggerUIBundle({
      spec: spec,
      dom_id: '#docs',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIStandalonePreset
      ]
    })
  }
}
</script>
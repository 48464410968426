<template>
  <v-dialog v-model="show" max-width="1000px" @keydown.esc="cancel()">

    <v-card>
      <v-card-title class="body-2 font-weight-bold" color="primary lighten-2 white--text" text>
        Create new dynamic environment
      </v-card-title>
      <form ref="envForm" @submit.prevent="submit">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pa-0 pr-2">
                <v-text-field
                    v-model="$v.env.name.$model"
                    :error="hasErrors($v.env.name)"
                    :messages="getErrors('name', $v.env.name)"
                    :persistent-hint="true"
                    clearable
                    label="Name"
                    @blur="$v.env.name.$touch()"
                    @input="$v.env.name.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Name
                    </template>
                </v-text-field>
              </v-col>
              <v-col class="pa-0 pl-2">
                <v-autocomplete
                    v-model="$v.domain.$model"
                    :error="hasErrors($v.domain)"
                    :items="validDomains"
                    :messages="getErrors('domain', $v.domain)"
                    label="Domain suffix"
                    @blur="$v.domain.$touch()"
                    @input="$v.domain.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Domain suffix
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.domain_data.domain"
                    :error="hasErrors($v.env.details.domain_data.domain) > 0"
                    :messages="getErrors('details.domain_data.domain', $v.env.details.domain_data.domain)"
                    clearable
                    label="Domain"
                    @blur="$v.env.details.domain_data.domain.$touch()"
                    @input="$v.env.details.domain_data.domain.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Domain
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.domain_data.enterprise_domain"
                    :error="hasErrors($v.env.details.domain_data.enterprise_domain) > 0"
                    :messages="getErrors('details.domain_data.enterprise_domain', $v.env.details.domain_data.enterprise_domain)"
                    clearable
                    label="Enterprise domain"
                    @blur="$v.env.details.domain_data.enterprise_domain.$touch()"
                    @input="$v.env.details.domain_data.enterprise_domain.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Enterprise domain
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.domain_data.second_enterprise_domain"
                    :error="hasErrors($v.env.details.domain_data.second_enterprise_domain) > 0"
                    :messages="getErrors('details.domain_data.second_enterprise_domain', $v.env.details.domain_data.second_enterprise_domain)"
                    clearable
                    label="2nd Enterprise domain"
                    @blur="$v.env.details.domain_data.second_enterprise_domain.$touch()"
                    @input="$v.env.details.domain_data.second_enterprise_domain.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>2nd Enterprise domain
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.core_microservices"
                    clearable
                    label="core microservices"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0 pr-2" cols="3">
                <v-autocomplete
                    v-model="$v.env.project_name.$model"
                    :error="hasErrors($v.env.project_name)"
                    :items="$store.getters.projects"
                    :messages="getErrors('details.project_name', $v.env.project_name)"
                    label="Project name"
                    @blur="$v.env.project_name.$touch()"
                    @input="$v.env.project_name.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Project name
                    </template>
                </v-autocomplete>
              </v-col>

              <v-col class="pa-0 pr-2" cols="3">
                <v-text-field
                    v-model="$store.getters.team"
                    label="Team name"
                    readonly>
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Team name
                    </template>
                </v-text-field>
              </v-col>

              <v-col class="pa-0 pl-2 pr-2" cols="3">
                <v-autocomplete
                    v-model="$v.env.env_size.$model"
                    :error="hasErrors($v.env.env_size)"
                    :items="$store.getters.sizes"
                    :messages="getErrors('env_size', $v.env.env_size)"
                    item-text="name"
                    item-value="name"
                    label="Environment size"
                    @blur="$v.env.env_size.$touch()"
                    @input="$v.env.env_size.$touch()"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Environment size
                  </template>
                  <template v-slot:selection="data">
                    <template
                        :input-value="data.selected"
                        close
                        v-bind="data.attrs"
                        @click="data.select"
                        @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </template>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <Tooltip
                            :distance="6"
                            class="pa-3"
                        >
                          <v-btn icon small>
                            <v-icon>info</v-icon>
                          </v-btn>

                          <template #popper>
                            <div class="ma-2 env_size_spec pa-2">
                              <v-row>
                                <v-col><strong>Node type</strong></v-col>
                                <v-col><strong>Configuration</strong></v-col>
                              </v-row>

                              <v-row>
                                <v-col>core</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.core_count}} x {{ data.item.instance_size.core_size}}</p>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>loadbalancer</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.loadbalancer_count}} x {{ data.item.instance_size.loadbalancer_size}}</p>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>agents</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.agent_count}} x {{ data.item.instance_size.agent_size}}</p>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>kube master</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.kubemaster_count}} x {{ data.item.instance_size.kubemaster_size}}</p>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>kube worker</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.kubeworker_count}} x {{ data.item.instance_size.kubeworker_size}}</p>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>mongo</v-col>
                                <v-col>
                                  <p>{{ data.item.instance_count.mongo_count}} x {{ data.item.instance_size.mongo_size}}</p>
                                </v-col>
                              </v-row>
                            </div>
                          </template>

                        </Tooltip>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>

                </v-autocomplete>
              </v-col>
              <v-col class="pa-0 pl-2 pr-2" cols="3">
                <v-autocomplete
                    v-model="$v.env.ttl.$model"
                    :error="hasErrors($v.env.ttl)"
                    :items="ttls"
                    :messages="getErrors('details.ttl', $v.env.ttl)"
                    item-text="display"
                    item-value="value"
                    label="Environment TTL"
                    @blur="$v.env.ttl.$touch()"
                    @input="$v.env.ttl.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Environment TTL
                    </template>
                </v-autocomplete>
              </v-col>
              
              <v-col class="pa-0 pl-2" cols="3">
                <v-autocomplete
                    v-model="$v.env.aws_region.$model"
                    :error="hasErrors($v.env.aws_region)"
                    :items="supportedRegions"
                    :messages="getErrors('aws_region', $v.env.aws_region)"
                    item-text="display"
                    item-value="value"
                    label="AWS Region"
                    @blur="$v.env.aws_region.$touch()"
                    @input="$v.env.aws_region.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>AWS Region
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              

              <v-col class="pa-0" cols="3">
                <v-checkbox v-model="env.details.standalone_mongodb" :label="'Deploy standalone mongodb cluster'">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Deploy standalone mongodb cluster
                  </template>
                </v-checkbox>
              </v-col>

              <v-col class="pa-0" cols="3">
                <v-checkbox v-model="env.details.block_outside" :label="'Block management tenant from outside?'"></v-checkbox>
              </v-col>

              <v-col class="pa-0" cols="3">
                <v-checkbox v-model="env.monitoring_enabled" :label="'Deploy grafana / prometheus to track logs and metrics?'"></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="env.details.admin_username" justify="space-between">
              <v-col class="pa-0 pr-2" cols="6">
                <v-text-field
                    v-model="env.details.admin_username"
                    :error="hasErrors($v.env.details.admin_username)"
                    :messages="getErrors('details.admin_username', $v.env.details.dremio_admin_password)"
                    label="Admin username"
                    @blur="$v.env.details.dremio_admin_password.$touch()"
                    @input="$v.env.details.dremio_admin_password.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Admin username
                    </template>
                </v-text-field>

              </v-col>

              <v-col class="pa-0 pl-2" cols="6">
                <v-text-field
                    v-model="env.details.admin_password"
                    :error="hasErrors($v.env.details.admin_password)"
                    :messages="getErrors('details.admin_password', $v.env.details.admin_password)"
                    :type="passwordToggle.admin_password ? 'password' : 'text'"
                    label="Admin password"
                    @blur="$v.env.details.admin_password.$touch()"
                    @input="$v.env.details.admin_password.$touch()"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Admin password
                  </template>
                  <template slot="append">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn icon small
                               v-bind="attrs"
                               @click="() => (passwordToggle.admin_password = !passwordToggle.admin_password)"
                               v-on="on"
                        >
                          <v-icon v-if="passwordToggle.admin_password">visibility</v-icon>
                          <v-icon v-if="!passwordToggle.admin_password">visibility_off</v-icon>
                        </v-btn>
                      </template>
                      <span>Show / hide password</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                            class="ml-2" icon small
                            v-bind="attrs"
                            @click="env.details.admin_password = generatePassword()"
                            v-on="on"
                        ><v-icon>refresh</v-icon></v-btn>
                      </template>

                      <span>Generate random password</span>
                    </v-tooltip>

                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0 pr-2" cols="4">
                <v-text-field
                    v-model="env.details.version_data.version"
                    :error="hasErrors($v.env.details.version_data.version) > 0"
                    :messages="getErrors('details.version_data.version', $v.env.details.version_data.version)"
                    clearable
                    label="Version"
                    @blur="$v.env.details.version_data.version.$touch()"
                    @input="$v.env.details.version_data.version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Version
                    </template>
                </v-text-field>
              </v-col>

              <v-col class="pa-0 pl-2" cols="4">
                <v-text-field
                    v-model="env.details.version_data.chart_version"
                    :error="hasErrors($v.env.details.version_data.chart_version) > 0"
                    :messages="getErrors('details.version_data.chart_version', $v.env.details.version_data.chart_version)"
                    clearable
                    label="Core helm chart version"
                    :persistent-hint="true"
                    hint="Cumulocity-ontoplb and ssl-management helm-charts are enabled above core version 1018.315.0. Please
                    fill the valid component chart-version in charts section."
                    @blur="$v.env.details.version_data.chart_version.$touch()"
                    @input="$v.env.details.version_data.chart_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Core helm chart version
                    </template>
                </v-text-field>
              </v-col>

              <v-col class="pa-0 pl-2" cols="4">
                <v-text-field
                    v-model="env.details.version_data.lwm2m_version"
                    :error="hasErrors($v.env.details.version_data.lwm2m_version) > 0"
                    :messages="getErrors('details.version_data.lwm2m_version', $v.env.details.version_data.lwm2m_version)"
                    clearable
                    label="Lwm2m version"
                    @blur="$v.env.details.version_data.lwm2m_version.$touch()"
                    @input="$v.env.details.version_data.lwm2m_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Lwm2m version
                    </template>
                </v-text-field>
              </v-col>

            </v-row>

            <v-row justify="space-between">

              <v-col class="pa-0 pr-2" cols="6">
                <v-text-field
                    v-model="env.details.version_data.cookbooks_version"
                    :error="hasErrors($v.env.details.version_data.cookbooks_version) > 0"
                    :messages="getErrors('details.version_data.cookbooks_version', $v.env.details.version_data.cookbooks_version)"
                    clearable
                    label="Cookbooks version"
                    @blur="$v.env.details.version_data.cookbooks_version.$touch()"
                    @input="$v.env.details.version_data.cookbooks_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Cookbooks version
                    </template>
                </v-text-field>
              </v-col>

              <v-col class="pa-0 pl-2" cols="6">
                <v-autocomplete
                    v-model="$v.env.details.chef_organization.$model"
                    :error="hasErrors($v.env.details.chef_organization)"
                    :items="['cumulocity-devel', 'cumulocity-stagings']"
                    :messages="getErrors('details.chef_organization', $v.env.details.chef_organization)"
                    label="Chef organization"
                    @blur="$v.env.details.chef_organization.$touch()"
                    @input="$v.env.details.chef_organization.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Chef organization
                    </template>
                </v-autocomplete>
              </v-col>

            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0 pl-2" cols="6">
                <v-autocomplete
                    v-model="$v.env.details.yum_repository.$model"
                    :error="hasErrors($v.env.details.yum_repository)"
                    :items="yum_repos"
                    :messages="getErrors('details.yum_repository', $v.env.details.yum_repository)"
                    label="yum repository"
                    @blur="$v.env.details.yum_repository.$touch()"
                    @input="$v.env.details.yum_repository.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>yum repository
                    </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pa-0 pr-2" cols="6">
                <v-autocomplete
                    v-model="$v.env.details.ms_download_url.$model"
                    :error="hasErrors($v.env.details.ms_download_url)"
                    :items="ms_repos"
                    :messages="getErrors('details.ms_download_url', $v.env.details.ms_download_url)"
                    label="microservice download url"
                    @blur="$v.env.details.ms_download_url.$touch()"
                    @input="$v.env.details.ms_download_url.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>microservice download url
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0 pl-2" cols="6">
                <v-autocomplete
                    v-model="$v.env.details.registry_url.$model"
                    :error="hasErrors($v.env.details.registry_url)"
                    :items="registries"
                    :messages="getErrors('details.registry_url', $v.env.details.registry_url)"
                    label="registry url"
                    @blur="$v.env.details.registry_url.$touch()"
                    @input="$v.env.details.registry_url.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>registry url
                    </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pa-0 pr-2" cols="6">
                <v-autocomplete
                    v-model="$v.env.details.registry_repo.$model"
                    :error="hasErrors($v.env.details.registry_repo)"
                    :items="registry_repos"
                    :messages="getErrors('details.registry_repo', $v.env.details.registry_repo)"
                    label="registry repository"
                    @blur="$v.env.details.registry_repo.$touch()"
                    @input="$v.env.details.registry_repo.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>registry repository
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0 pl-2" cols="4">
                <v-autocomplete
                    v-model="env.envs_branch"
                    :error="hasErrors($v.env.envs_branch) > 0"
                    :items="c8y_envs_branches"
                    :messages="getErrors('envs_branch', $v.env.envs_branch)"
                    item-text="name"
                    item-value="name"
                    label="c8y_envs repository branch"
                    @blur="$v.env.envs_branch.$touch()"
                    @input="$v.env.envs_branch.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>c8y_envs repository branch
                    </template>
                </v-autocomplete>
              </v-col>

              <v-col class="pa-0 pl-2 pr-2" cols="4">
                <v-autocomplete
                    v-model="env.provisioners_branch"
                    :error="hasErrors($v.env.provisioners_branch) > 0"
                    :items="c8y_provisioners_branches"
                    :messages="getErrors('provisioners_branch', $v.env.provisioners_branch)"
                    item-text="name"
                    item-value="name"
                    label="c8y_provisioners repository branch"
                    @blur="$v.env.provisioners_branch.$touch()"
                    @input="$v.env.provisioners_branch.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>c8y_provisioners repository branch
                    </template>
                </v-autocomplete>
              </v-col>

              <v-col class="pa-0 pr-2" cols="4">
                <v-autocomplete
                    v-model="env.concourse_branch"
                    :error="hasErrors($v.env.concourse_branch) > 0"
                    :items="c8y_concourse_branches"
                    :messages="getErrors('concourse_branch', $v.env.concourse_branch)"
                    item-text="name"
                    item-value="name"
                    label="c8y_concourse repository branch"
                    @blur="$v.env.concourse_branch.$touch()"
                    @input="$v.env.concourse_branch.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>c8y_concourse repository branch
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.pulsar_deploy" :label="'Do you want to deploy pulsar?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.pulsar_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.pulsar_version"
                    :error="hasErrors($v.env.details.pulsar_version) > 0"
                    :messages="getErrors('details.pulsar_version', $v.env.details.pulsar_version)"
                    clearable
                    label="Pulsar version"
                    @blur="$v.env.details.pulsar_version.$touch()"
                    @input="$v.env.details.pulsar_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Pulsar version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.datahub_deploy" :label="'Do you want to deploy CDH?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.datahub_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.datahub_version"
                    :error="hasErrors($v.env.details.datahub_version) > 0"
                    :messages="getErrors('details.datahub_version', $v.env.details.datahub_version)"
                    clearable
                    label="Datahub version"
                    @blur="$v.env.details.datahub_version.$touch()"
                    @input="$v.env.details.datahub_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Datahub version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="env.details.datahub_deploy" justify="space-between">
              <v-col class="pa-0 pr-2" cols="6">
                <v-text-field
                    v-model="env.details.dremio_version"
                    :error="hasErrors($v.env.details.dremio_version) > 0"
                    :messages="getErrors('details.dremio_version', $v.env.details.dremio_version)"
                    clearable
                    label="Dremio version"
                    @blur="$v.env.details.dremio_version.$touch()"
                    @input="$v.env.details.dremio_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Dremio version
                    </template>
                </v-text-field>
              </v-col>

              <v-col class="pa-0 pl-2" cols="6">
                <v-text-field
                    v-model="env.details.dremio_chart_version"
                    :error="hasErrors($v.env.details.dremio_chart_version) > 0"
                    :messages="getErrors('details.dremio_chart_version', $v.env.details.dremio_chart_version)"
                    clearable
                    label="Dremio chart version"
                    @blur="$v.env.details.dremio_chart_version.$touch()"
                    @input="$v.env.details.dremio_chart_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Dremio chart version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="env.details.datahub_deploy" justify="space-between">
              <v-col class="pa-0 pr-2" cols="6">
                <v-text-field
                    v-model="env.details.dremio_admin_password"
                    :error="hasErrors($v.env.details.dremio_admin_password) > 0"
                    :messages="getErrors('details.dremio_admin_password', $v.env.details.dremio_admin_password)"
                    :type="passwordToggle.dremio_admin_password ? 'password' : 'text'"
                    label="Dremio admin password"
                    @blur="$v.env.details.dremio_admin_password.$touch()"
                    @input="$v.env.details.dremio_admin_password.$touch()"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Dremio admin password
                  </template>
                  <template slot="append">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn icon small
                          v-bind="attrs"
                           @click="() => (passwordToggle.dremio_admin_password = !passwordToggle.dremio_admin_password)"
                           v-on="on"
                        >
                          <v-icon v-if="passwordToggle.dremio_admin_password">visibility</v-icon>
                          <v-icon v-if="!passwordToggle.dremio_admin_password">visibility_off</v-icon>
                        </v-btn>
                      </template>
                      <span>Show / hide password</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                            class="ml-2" icon small
                            v-bind="attrs"
                            @click="env.details.dremio_admin_password = generatePassword()"
                            v-on="on"
                        ><v-icon>refresh</v-icon></v-btn>
                      </template>

                      <span>Generate random password</span>
                    </v-tooltip>

                  </template>
                </v-text-field>

              </v-col>

              <v-col class="pa-0 pl-2" cols="6">
                <v-text-field
                    v-model="env.details.dremio_api_password"
                    :error="hasErrors($v.env.details.dremio_api_password) > 0"
                    :messages="getErrors('details.dremio_api_password', $v.env.details.dremio_api_password)"
                    :type="passwordToggle.dremio_api_password ? 'password' : 'text'"
                    label="Dremio api password"
                    @blur="$v.env.details.dremio_api_password.$touch()"
                    @input="$v.env.details.dremio_api_password.$touch()"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Dremio api password
                  </template>
                  <template slot="append">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn icon small
                               v-bind="attrs"
                               @click="() => (passwordToggle.dremio_api_password = !passwordToggle.dremio_api_password)"
                               v-on="on"
                        >
                          <v-icon v-if="passwordToggle.dremio_api_password">visibility</v-icon>
                          <v-icon v-if="!passwordToggle.dremio_api_password">visibility_off</v-icon>
                        </v-btn>
                      </template>
                      <span>Show / hide password</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                            class="ml-2" icon small
                            v-bind="attrs"
                            @click="env.details.dremio_api_password = generatePassword()"
                            v-on="on"
                        ><v-icon>refresh</v-icon></v-btn>
                      </template>

                      <span>Generate random password</span>
                    </v-tooltip>

                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.apama_deploy" :label="'Do you want to deploy apama?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.apama_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.apama_version"
                    :error="hasErrors($v.env.details.apama_version) > 0"
                    :messages="getErrors('details.apama_version', $v.env.details.apama_version)"
                    clearable
                    label="Apama version"
                    @blur="$v.env.details.apama_version.$touch()"
                    @input="$v.env.details.apama_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Apama version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.cep_deploy" :label="'Do you want to deploy CEP?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.cep_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.cep_version"
                    :error="hasErrors($v.env.details.cep_version) > 0"
                    :messages="getErrors('details.cep_version', $v.env.details.cep_version)"
                    clearable
                    label="CEP version"
                    @blur="$v.env.details.cep_version.$touch()"
                    @input="$v.env.details.cep_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>CEP version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.zementis_deploy" :label="'Do you want to deploy zementis?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.zementis_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.zementis_version"
                    :error="hasErrors($v.env.details.zementis_version) > 0"
                    :messages="getErrors('details.zementis_version', $v.env.details.zementis_version)"
                    clearable
                    label="Zementis version"
                    @blur="$v.env.details.zementis_version.$touch()"
                    @input="$v.env.details.zementis_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Zementis version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.mlw_deploy" :label="'Do you want to deploy mlw?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.mlw_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.mlw_version"
                    :error="hasErrors($v.env.details.mlw_version) > 0"
                    :messages="getErrors('details.mlw_version', $v.env.details.mlw_version)"
                    clearable
                    label="Mlw version"
                    @blur="$v.env.details.mlw_version.$touch()"
                    @input="$v.env.details.mlw_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Mlw version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="pa-0" cols="4">
                <v-checkbox v-model="env.details.dtm_deploy" :label="'Do you want to deploy dtm?'"></v-checkbox>
              </v-col>
              <v-col v-if="env.details.dtm_deploy" class="pa-0" cols="7">
                <v-text-field
                    v-model="env.details.dtm_version"
                    :error="hasErrors($v.env.details.dtm_version) > 0"
                    :messages="getErrors('details.dtm_version', $v.env.details.dtm_version)"
                    clearable
                    label="Dtm version"
                    @blur="$v.env.details.dtm_version.$touch()"
                    @input="$v.env.details.dtm_version.$touch()">
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Dtm version
                    </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.microservices_list"
                    :error="hasErrors($v.env.details.microservices_list) > 0"
                    :messages="getErrors('details.microservices_list', $v.env.details.microservices_list)"
                    :persistent-hint="true"
                    clearable
                    hint="Comma separated list of microservices(Without space) with full path after /var/www/staging-resources/. Example: sag/cep/<version>/kubernetes-images/cep.zip for layeredproducts and kubernetes-images/kubernetes-images/report-agent-<version>.zip for common microservices"
                    label="List of microservices"
                    @blur="$v.env.details.microservices_list.$touch()"
                    @input="$v.env.details.microservices_list.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="pa-0">
                <v-text-field
                    v-model="env.details.webapps_list"
                    :error="hasErrors($v.env.details.webapps_list) > 0"
                    :messages="getErrors('details.webapps_list', $v.env.details.webapps_list)"
                    :persistent-hint="true"
                    clearable
                    hint="Comma separated list of webapps(Without space) with full path after /var/www/staging-resources/. Example: sag/apama/10.15.0.0.20220822-0305-066bd2624/webapps/streaming-analytics-app.zip,sag/zementis/10.15.0.0.26/webapps/machine-learning.zip"
                    label="List of webapps"
                    @blur="$v.env.details.webapps_list.$touch()"
                    @input="$v.env.details.webapps_list.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col class="v-label theme--light">Add list of helm chart components  <v-btn variant="flat" x-small color="green" @click="addHelmComponent()"><v-icon small>
                        add
                      </v-icon>
                    </v-btn>
              </v-col>
            </v-row>

            <p class="v-label theme--light" v-if="env.details.helm_components.length === 0">No helm chart components present</p>

            <v-row v-for="(component, i) in env.details.helm_components"
                  :key="i"
                  class="list-group list-group-flush">
              <v-col>
                <v-text-field
                    v-model="component.chart_repository"
                    label="Chart repository"
                    :persistent-hint="true"
                    required></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="component.chart_name"
                    label="Chart name"
                    :persistent-hint="true"
                    required></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="component.chart_version"
                    label="Chart version"
                    :persistent-hint="true"
                    required>
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Chart version
                    </template>
                  </v-text-field>
                  <div v-if="component.chart_version.length === 0">
                    <a target="_blank" @click="navigateToStagingApproved">Click to view the component's chart version from respective build descriptor</a>
                  </div>
              </v-col>
              <v-col>
                <v-btn variant="flat"
                    color="red"
                    x-small
                    align="center"
                    @click="removeHelmComponent(i)">
                  <v-icon small>
                    delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :loading="loading" class="success mb-3 mt-3" text type="submit">Create</v-btn>
          <v-btn :loading="loading" class="info mb-3 mt-3" text @click.native="cancel()">Cancel</v-btn>
        </v-card-actions>

      </form>

    </v-card>
  </v-dialog>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import * as _ from "lodash"
import { required, requiredIf, helpers } from '@vuelidate/validators';
// import {isValidSemver} from '@/utils';

import {
  Tooltip
} from 'floating-vue'
import Vue from "vue";
import isSemver from "is-semver";
import isEmpty from "lodash";
import {mapActions, mapGetters} from "vuex";

const isValidSemver = helpers.withMessage((params) => {
  return `${params.$model} is not valid semver`
}, (value) => {
  if (value === undefined || isEmpty(value.trim())) {
    return true
  }

  return isSemver(value)
})


const isValidDomain = helpers.withMessage((params) => {
  return `${params.$model} does not much selected domain suffix`
}, (value, siblings, vm) => {
  return value.endsWith(vm.domain)
})

export default {
  name: 'CreateEnv',
  components: {Tooltip},
  setup: () => ({ $v: useVuelidate() }),
  computed: {
    ...mapGetters({
      'branchesFor': 'github/branches',
    }),
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  data: () => ({
    supportedRegions: ["eu-north-1", "eu-central-1"],
    validationErrors: [],
    // teams: ['', 'C8Y Core', 'Platform Management', 'Authentication and Authorisation', 'Data in Motion', 'Data at Rest', 'LPWAN Integrations', 'LWM2M and OPCUA', 'Data and Control', 'Performance', 'Product Infrastructure', 'Microservice Hosting', 'SaaS Enablement', 'Cumulocity IoT OEE', 'CD', 'CI', 'Apama in Cumulocity', 'Cumulocity DataHub', 'Cumulocity Digital Twin Manager', 'Cumulocity IoT Machine Learning', 'App Enablement', 'Cumulocity IoT Edge', 'Cumulocity IoT Thin Edge', 'CloudOps', 'Documentation', 'Security', 'Release Performance Test', 'Common Infra'],
    yum_repos: ['staging-resources.cumulocity.com', 'approved-resources.cumulocity.com', 'yum.cumulocity.com'],
    ms_repos: ['staging-resources.cumulocity.com', 'approved-resources.cumulocity.com', 'resources.cumulocity.com'],
    registries: ['registry.stage.c8y.io', 'registry.c8y.io'],
    registry_repos: ['stack', 'testing'],
    ttls: [
      { display: '4 hours', value: 4 },
      { display: '8 hours', value: 8 },
      { display: '1 day', value: 24 },
      { display: '2 days', value: 48 },
      { display: '3 days', value: 72 },
      { display: '5 days', value: 120 },
      { display: '1 week', value: 168 },
      { display: '2 weeks', value: 336 },
      { display: '3 weeks', value: 504 },
      { display: '4 weeks', value: 672 },
      { display: 'no TTL', value : 0 }
    ],
    c8y_envs_branches: [],
    c8y_provisioners_branches: [],
    c8y_concourse_branches: [],
    passwordToggle: {
      admin_password: true,
      dremio_admin_password: true,
      dremio_api_password: true
    },
    validDomains: ['stage.c8y.io', 'dev.c8y.io', 'performance.c8y.io'],
    domain: "stage.c8y.io",
    env: {
      "name": "",
      "details": {
        "helm_components": [],
        "version_data": {
          "version": "",
          "chart_version": "",
          "cookbooks_version": "",
          "lwm2m_version": ""
        },
        "domain_data": {
          "domain": "",
          "enterprise_domain": "",
          "second_enterprise_domain": ""
        },
        "chef_organization": "cumulocity-devel",
        "admin_username": "admin",
        "admin_password": "",
        "containerized_core": true,

        "standalone_mongodb": true,
        "vpc_cidr": "",
        "core_microservices": "cellid,cloud-remote-access,connectivity-agent-server,databroker-agent-server,device-simulator,echo-agent-server,impact,loriot-agent,mockserver,opcua-mgmt-service,provisioning,report-agent,retention,sigfox-agent,smartrule,sms-gateway,sslmonitor-agent-server",
        "pulsar_deploy": false,
        "pulsar_version": "1014.1.0",
        "datahub_deploy": false,
        "datahub_version": "10.15.0.1.97",
        "dremio_admin_password": "",
        "dremio_api_password": "",
        "dremio_version": "10.15.0.0.18",
        "dremio_chart_version": "0.0.0",
        "apama_deploy": false,
        "apama_version": "10.15.0.0.20220822-0305-066bd2624",
        "zementis_deploy": false,
        "zementis_version": "10.15.0.0.26",
        "mlw_deploy": false,
        "mlw_version": "10.15.0.0.165",
        "cep_deploy": false,
        "cep_version": "10.15.0.124",
        "dtm_deploy": false,
        "dtm_version": "1015.0.0.272",
        "microservices_list": "",
        "webapps_list": "",
        "yum_repository": "staging-resources.cumulocity.com",
        "ms_download_url": "staging-resources.cumulocity.com",
        "registry_url": "registry.stage.c8y.io",
        "registry_repo": "stack",
        "block_outside": true
      },
      "monitoring_enabled": false,
      "envs_branch": "staging",
      "provisioners_branch": "master",
      "concourse_branch": "master",
      "ttl": 24,
      "aws_region": "eu-north-1",
      "stop_tag": "Yes",
      "project_name": "",
      "env_size": "extra-small"
    },
    dialog: false,
    loading: false,
    resolve: null,
    reject: null,
    validationMessages: {
      name: {
        required: "This field is required"
      },
      "details.domain": "Invalid domain"
    }
  }),
  mounted() {
    this.$store.dispatch('getProjects')
    this.$store.dispatch('fetchEnvSizes')
    this.env.details.admin_password = this.generatePassword();
    this.env.details.dremio_admin_password = this.generatePassword();
    this.env.details.dremio_api_password = this.generatePassword();

    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_envs'}).then(() => {
      this.c8y_envs_branches = this.branchesFor("Cumulocity-IoT/c8y_envs")
    });

    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_concourse'}).then(() => {
      this.c8y_concourse_branches = this.branchesFor("Cumulocity-IoT/c8y_concourse")
    })
    this.fetchBranches({owner: 'Cumulocity-IoT', repo: 'c8y_provisioners'}).then(() => {
      this.c8y_provisioners_branches = this.branchesFor("Cumulocity-IoT/c8y_provisioners")
    })


  },
  watch: {
    'env_size': {
      handler(newVal) {
        console.log(newVal)
      }
    },
    'env.project_name': {
      handler(newVal) {
        this.$store.dispatch('getTeamForProject', newVal)
      }
    },
    'domain': {
      handler(newVal) {
        this.env.details.domain_data.domain = this.stripDomain(this.env.details.domain_data.domain, false) + "." + newVal;
        this.env.details.domain_data.enterprise_domain = this.stripDomain(this.env.details.domain_data.enterprise_domain, false) + "." + newVal;
        this.env.details.domain_data.second_enterprise_domain = this.stripDomain(this.env.details.domain_data.second_enterprise_domain, false) + "." + newVal;

        this.$v.env.details.domain_data.domain.$touch();
        this.$v.env.details.domain_data.enterprise_domain.$touch();
        this.$v.env.details.domain_data.second_enterprise_domain.$touch();
      }
    },
    'env.details.version_data.version': {
      handler(newVal, prevVal) {
        const oldVersion = prevVal.split('-')[0]
        const newVersion = newVal.split('-')[0]

        if (newVal.match('.*-SNAPSHOT\\d+') && !this.$v.env.details.registry_repo.$dirty) {
          this.env.details.registry_repo = "testing"
        } else if (newVal.endsWith('-1')) {
          this.env.details.registry_repo = "stack"
        }

        if (this.env.details.version_data.cookbooks_version === oldVersion || _.isEmpty(this.env.details.version_data.cookbooks_version)) {
          this.env.details.version_data.cookbooks_version = newVersion
          this.$v.env.details.version_data.cookbooks_version.$touch();
        }

        if (this.env.details.version_data.chart_version === oldVersion || _.isEmpty(this.env.details.version_data.chart_version)) {
          this.env.details.version_data.chart_version = newVersion
          this.$v.env.details.version_data.chart_version.$touch();
        }

        if (this.env.details.version_data.lwm2m_version === oldVersion || _.isEmpty(this.env.details.version_data.lwm2m_version)) {
          this.env.details.version_data.lwm2m_version = newVersion
          this.$v.env.details.version_data.lwm2m_version.$touch();
        }
      }
    },
    'env.name': {
      handler(newVal, prevVal) {
        this.calculateDomains(prevVal);
      }
    },
    'env.details.version_data.chart_version': {
      handler(newVal) {
        this.addOntoplbHelmChart(newVal);
        this.addSslManagementHelmChart(newVal);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      'fetchBranches': 'github/fetchBranches'
    }),
    addHelmComponent() {
      this.env.details.helm_components.push({
        chart_repository: "",
        chart_name: "",
        chart_version: ""
      })
      return false;
    },
    removeHelmComponent(index) {
      this.env.details.helm_components.splice(index, 1)
    },
    addOntoplbHelmChart(version) {
      if ( version > '1018.315.0' ) {
        if (_.isEmpty(this.env.details.helm_components)){
          this.env.details.helm_components.push({
            chart_repository: "product-infrastructure",
            chart_name: "cumulocity-ontoplb",
            chart_version: ""
          })
        } else {
          if(!this.env.details.helm_components.some(data => data.chart_name === "cumulocity-ontoplb")){
            this.env.details.helm_components.push({
              chart_repository: "product-infrastructure",
              chart_name: "cumulocity-ontoplb",
              chart_version: ""
            })
          }
        }
      } else {
        var chartLen = this.env.details.helm_components.length
        for(var index = 0; index < chartLen; index++) {
          if (this.env.details.helm_components[index].chart_name === "cumulocity-ontoplb"){
            this.env.details.helm_components.splice(index, 1)
          }
        }
      }
      return false;
    },
    addMsRegistryHelmChart(version) {
      if ( version > '1018.0.0' ) {
        if (_.isEmpty(this.env.details.helm_components)){
          this.env.details.helm_components.push({
            chart_repository: "stack",
            chart_name: "microservices-registry",
            chart_version: ""
          })
        } else {
          if(!this.env.details.helm_components.some(data => data.chart_name === "microservices-registry")){
            this.env.details.helm_components.push({
              chart_repository: "stack",
              chart_name: "microservices-registry",
              chart_version: ""
            })
          }
        }
      } else {
        var chartLen = this.env.details.helm_components.length
        for(var index = 0; index < chartLen; index++) {
          if (this.env.details.helm_components[index].chart_name === "microservices-registry"){
            this.env.details.helm_components.splice(index, 1)
          }
        }
      }
      return false;
    },
    addSslManagementHelmChart(version) {
      if ( version > '1018.315.0' ) {
        if (_.isEmpty(this.env.details.helm_components)){
          this.env.details.helm_components.push({
            chart_repository: "stack",
            chart_name: "ssl-management",
            chart_version: ""
          })
        } else {
          if(!this.env.details.helm_components.some(data => data.chart_name === "ssl-management")){
            this.env.details.helm_components.push({
              chart_repository: "stack",
              chart_name: "ssl-management",
              chart_version: ""
            })
          }
        }
      } else {
        var chartLen = this.env.details.helm_components.length
        for(var index = 0; index < chartLen; index++) {
          if (this.env.details.helm_components[index].chart_name === "ssl-management"){
            this.env.details.helm_components.splice(index, 1)
          }
        }
      }
      return false;
    },
    addActilityHelmChart(version) {
      if ( version > '1018.315.0' ) {
        if (_.isEmpty(this.env.details.helm_components)){
          this.env.details.helm_components.push({
            chart_repository: "data-and-control",
            chart_name: "actility",
            chart_version: ""
          })
        } else {
          if(!this.env.details.helm_components.some(data => data.chart_name === "actility")){
            this.env.details.helm_components.push({
              chart_repository: "data-and-control",
              chart_name: "actility",
              chart_version: ""
            })
          }
        }
      } else {
        var chartLen = this.env.details.helm_components.length
        for(var index = 0; index < chartLen; index++) {
          if (this.env.details.helm_components[index].chart_name === "actility"){
            this.env.details.helm_components.splice(index, 1)
          }
        }
      }
      return false;
    },
    generatePassword() {
      let characterSet = ''
      let lowerCharSet = 'abcdefghijklmnopqrstuvwxyz'
      let upperCharSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let numberSet = '0123456789'
      let specialCharSet = '*#!'
      characterSet = lowerCharSet + upperCharSet + specialCharSet + numberSet
      let minSpecialChar = 2
      let minNum         = 2
      let minUpperCase   = 2
      let remainingLength = ''

      let password = ''
      let size = 16

      for (let i = 0; i < minSpecialChar; i++) {
        password += specialCharSet.charAt(Math.floor(Math.random() * specialCharSet.length))
      }

      for (let i = 0; i < minNum; i++) {
        password += numberSet.charAt(Math.floor(Math.random() * numberSet.length))
      }

      for (let i = 0; i < minUpperCase; i++) {
        password += upperCharSet.charAt(Math.floor(Math.random() * upperCharSet.length))
      }

      remainingLength = size - minSpecialChar - minNum - minUpperCase
      
      for (let i = 0; i < remainingLength; i++) {
        password += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
      }

      return password
    },
    calculateDomains(previousValue) {
      let domain = this.env.name + "." + this.domain;
      let enterpriseDomain = this.env.name + ".enterprise." + this.domain;
      let secondEnterpriseDomain = this.env.name + ".enterprise-second." + this.domain;

      if (_.isEmpty(this.env.details.domain_data.domain) || this.stripDomain(this.env.details.domain_data.domain, true) === previousValue) {
        this.env.details.domain_data.domain = domain;
        this.$v.env.details.domain_data.domain.$touch();
      }

      if (_.isEmpty(this.env.details.domain_data.enterprise_domain) || this.stripDomain(this.env.details.domain_data.enterprise_domain, true) === previousValue) {
        this.env.details.domain_data.enterprise_domain = enterpriseDomain;
        this.$v.env.details.domain_data.enterprise_domain.$touch();
      }

      if (_.isEmpty(this.env.details.domain_data.second_enterprise_domain) || this.stripDomain(this.env.details.domain_data.second_enterprise_domain, true) === previousValue) {
        this.env.details.domain_data.second_enterprise_domain = secondEnterpriseDomain;
        this.$v.env.details.domain_data.second_enterprise_domain.$touch();
      }
    },
    stripDomain(value, stripEnterprise = true) {
      _.forEach(this.validDomains, (suffix) => {
        if (stripEnterprise) {
          value = value.replace(".enterprise." + suffix, '')
          value = value.replace(".enterprise-second." + suffix, '')
        }

        value = value.replace("." + suffix, '')
      })

      return value;
    },
    cancel() {
      this.dialog = false
      this.resolve({
        confirmed: false
      })
    },
    hasErrors(entity) {
      let path = entity.$path.replace('env.', '');

      return this.getErrors(path, entity).length > 0;
    },
    getErrors(field, entity) {
      const errors = [];

      if (!entity.$dirty) {
        return errors
      }

      _.forEach(entity.$errors, (error) => {
        let message

        if (_.has(this.validationMessages, field) && _.has(this.validationMessages[field], error.$params.type)) {
          message = this.validationMessages[field][error.$params.type]
        } else {
          message = error.$message
        }

        errors.push(message);
      });

      if (_.has(this.validationErrors, field)) {
        _.forEach(this.validationErrors[field], (error) => {
          errors.push(error)
        })
      }

      return errors;

    },
    open() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    navigateToStagingApproved(){
      window.open('https://github.com/Cumulocity-IoT/c8y-iot-build-pipeline/tree/10.19.0.x/Staging-Approved/products', '_blank').focus()
    },
    submit() {

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$store.dispatch('notifyError', { text: 'There are invalid fields. Please resolve all issues and try again', dismissAfter: 5000})
        return
      }

      if (_.isEmpty(Vue.$keycloak.idTokenParsed.name)) {
        this.env.owner = Vue.$keycloak.idTokenParsed.preferred_username
      } else {
        this.env.owner = Vue.$keycloak.idTokenParsed.name
      }

      let newEnv = _.cloneDeep(this.env)
      newEnv.details.microservices_list = []

      if (!_.isEmpty(this.env.details.microservices_list) && this.env.details.microservices_list.trim().length > 0) {
        let microservicesList = this.env.details.microservices_list.split(',');

        microservicesList = _(microservicesList)
            .omitBy(_.isNil)
            .mapValues((elem) => elem.trim())
            .omitBy(_.isEmpty).values()

        newEnv.details.microservices_list = microservicesList
      }

      newEnv.details.webapps_list = []

      if (!_.isEmpty(this.env.details.webapps_list) && this.env.details.webapps_list.trim().length > 0) {
        let webappsList = this.env.details.webapps_list.split(',');

        webappsList = _(webappsList)
            .omitBy(_.isNil)
            .mapValues((elem) => elem.trim())
            .omitBy(_.isEmpty).values()

        newEnv.details.webapps_list = webappsList
      }

      this.$store.dispatch('createEnv', newEnv).then((result) => {
        this.validationErrors = []
        this.env.id = result.id

        this.dialog = false
        this.resolve(this.env)
      }, (errors) => {
        this.validationErrors = {}
        _.forEach(errors, (error) => {
          this.validationErrors[error.json_field] = error.messages
        })

        this.$store.dispatch('notifyError', { text: 'There are invalid fields. Please resolve all issues and try again', dismissAfter: 5000 })

      });
    }
  },
  validate() {
    this.$v.$touch();
  },
  validations() {
     return {
       domain: [required],
       env: {
         name: [required],
         details: {
           admin_username: [required],
           admin_password: [required],
           apama_version: [requiredIf(this.env.details.apama_deploy)],
           pulsar_version: [requiredIf(this.env.details.pulsar_deploy), isValidSemver],
           zementis_version: [requiredIf(this.env.details.zementis_deploy)],
           mlw_version: [requiredIf(this.env.details.mlw_deploy)],
           cep_version: [requiredIf(this.env.details.cep_deploy)],
           dtm_version: [requiredIf(this.env.details.dtm_deploy)],
           datahub_version: [requiredIf(this.env.details.datahub_deploy)],
           dremio_version: [requiredIf(this.env.details.datahub_deploy)],
           dremio_chart_version: [requiredIf(this.env.details.datahub_deploy), isValidSemver],
           dremio_admin_password: [requiredIf(this.env.details.datahub_deploy)],
           dremio_api_password: [requiredIf(this.env.details.datahub_deploy)],
           chef_organization: [required],
           version_data: {
             version: [required, isValidSemver],
             chart_version: [required, isValidSemver],
             cookbooks_version: [required, isValidSemver],
             lwm2m_version: [required, isValidSemver]
           },
           yum_repository: [required],
           ms_download_url: [required],
           registry_url: [required],
           registry_repo: [required],
           domain_data: {
             domain: [required, isValidDomain],
             enterprise_domain: [required, isValidDomain],
             second_enterprise_domain: [required, isValidDomain]
           },
           microservices_list: [],
           webapps_list: [],
           helm_components: [
            {
              chart_repository: [],
              chart_name: [],
              chart_version: [requiredIf(this.env.details.helm_components.chart_repository), requiredIf(this.env.details.helm_components.chart_name), isValidSemver]
            }
           ],
         },

         envs_branch: [required],
         provisioners_branch: [required],
         concourse_branch: [required],
         project_name: [required],
         ttl: [required],
         aws_region: [required],
         env_size: [required]
       }
     }
  },
}
</script>
<style lang="scss">
@import 'floating-vue/dist/style.css';

.env_size_spec {
  min-width: 300px;
}
</style>

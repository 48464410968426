<template>
  <div>
    <v-tabs v-model="currentItem" align-left flat >
      <v-tabs-slider></v-tabs-slider>

      <v-tab :key="'instance-list'">
        EC2 instances
      </v-tab>

      <v-tab :key="'details'">
        Details
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentItem">
      <v-tab-item :key="'instance-list'">
        <staging-instances :env="env"></staging-instances>
      </v-tab-item>
      <v-tab-item :key="'details'">
        <staging-details :env="env"></staging-details>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StagingDetails from "@/components/stagings/StagingDetails"
import StagingInstances from "@/components/stagings/StagingInstances"

export default {
  name: 'StagingEnvDetails',
  components: {StagingInstances, StagingDetails},
  props: [
    'env'
  ],
  // components: {StagingInstances, StagingDetails},
  data: () => {
    return {
      currentItem: 'instance-list',
    }
  }
}
</script>
<style type="scss">

</style>
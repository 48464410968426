<template>
  <div v-if="env">
    <div  class="container">
      <env-detail-heading :env="env" :link-enabled="false" class="mb-5 mt-5"></env-detail-heading>
      <env-detail :env="env"></env-detail>

      <v-speed-dial
          v-model="fab"
          :bottom="true"
          :left="false"
          :open-on-hover="true"
          :right="true"
          :top="false"
          class="mr-7 mb-3"
          direction="top"
      >
        <template v-slot:activator>
          <v-btn
              v-model="fab"
              color="blue darken-2 env-button"
              dark
              fab
              large
          >
            <v-icon v-if="fab">
              close
            </v-icon>
            <v-icon v-else>
              settings
            </v-icon>
          </v-btn>
        </template>

        <v-btn color="primary" @click="extendTTL">
          extend TTL
        </v-btn>

        <v-btn color="error" @click="terminate">
          terminate
        </v-btn>
      </v-speed-dial>
    </div>

    <DynamicEnvExtendTTL ref="ttlExtendDialog" :env="env"></DynamicEnvExtendTTL>
    <ConfirmDialogPopup ref="confirm"></ConfirmDialogPopup>
  </div>
</template>

<script>
import EnvDetailHeading from "@/components/environments/EnvDetailHeading";
import EnvDetail from "@/components/environments/EnvDetail";
import ConfirmDialogPopup from "@/components/uikit/ConfirmDialogPopup";
import DynamicEnvExtendTTL from "@/components/environments/DynamicEnvExtendTTL";

export default {
  name: 'DynamicEnvDetail',
  components: {DynamicEnvExtendTTL, ConfirmDialogPopup, EnvDetail, EnvDetailHeading},
  props: ['id'],
  data: () => {
    return {
      fab: false,
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchEnvDetails', this.$route.params.id)
    },
    extendTTL() {
      this.$refs.ttlExtendDialog.open()
    },
    terminate() {
      const options = {
        askForValue: true,
        askForValueLabel: "Please provide termination reason",
        messageToType: this.env.details.domain_data.domain
      };

      this.$refs.confirm.open(
          "Delete selected environment?",
          "Delete the environment permanently? This action cannot be undone. Your environment will become unavailable.",
          options
      ).then((result) => {
        if (result.confirmed) {
          this.$store.dispatch('terminate', {id: this.env.id, reason: result.value })
        }
      })
    }
  },

  computed: {
    env() {
      return this.$store.state.dynamic_envs.environment
    }
  },

  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    );

    this.refreshTimer = setInterval(() => {
      this.fetchData()
    }, 15000)
  },
}
</script>

<style lang="scss">
.v-speed-dial {
  position: fixed !important;
}

.v-btn--floating {
  position: relative;
}
</style>
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const token = localStorage.getItem("access_token")

let state = {
  authenticated: token != null && token.length > 0,
  token: token
}

const AuthModule = {
  state: () => (state),
  actions: actions,
  getters: getters,
  mutations: mutations
}

export default AuthModule

import axios from 'axios'
import store from '@/store'

const resource_uri = '/api/github'

const state = {
  branches: {}
}

const getters = {
  branches: (state) => (repo_key) => {
    return state.branches[repo_key]
  }
}

const actions = {
  async fetchBranches({commit}, payload) {
    const {owner, repo} = payload
    let token = store.state.auth.token;

    const response = await axios.get(`${resource_uri}/${owner}/${repo}/branches`,{
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
    });

    const repo_key = `${owner}/${repo}`
    commit('fetchBranches', {repo_key: repo_key, branches: response.data})
  }
}

const mutations = {
  fetchBranches: (state, data) => {
    state.branches[data.repo_key] = data.branches
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
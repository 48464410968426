<template>
  <div>
    <v-container v-if="$store.getters.hasInstances(env.id)" fluid>
      <v-row class="justify-space-between">
        <v-col>
          <v-text-field v-model="instanceSearch" label="Search instances"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-switch
              v-model="instanceShowTerminated"
              label="Display terminated instances"
          ></v-switch>
        </v-col>
      </v-row>

    </v-container>

    <div v-if="$store.getters.hasInstances(env.id)" class="pl-5 pr-5">
      <v-layout :align-content-center="true" class="pa-4 border_bottom" row wrap>
        <v-flex class="font-weight-bold" md3 xs12>
          Instance
        </v-flex>
        <v-flex class="font-weight-bold" md2 sm3 xs3>
          Launch time
        </v-flex>
        <v-flex class="font-weight-bold" md2 sm3 xs3>
          Private IP
        </v-flex>
        <v-flex class="font-weight-bold" md2 sm3 xs3>
          Public IP
        </v-flex>
        <v-flex class="font-weight-bold" md2 sm3 xs3>
          Instance state
        </v-flex>
        <v-flex class="font-weight-bold" md1 sm3 xs3>
          Start/stop
        </v-flex>
      </v-layout>
      <v-layout v-for="instance in filterInstances(env.id)" :key="instance.id" class="pa-4" row
                wrap>
        <v-flex md3 xs12>
          <p class="mb-0">{{ instance.id }}
            <v-icon
                v-clipboard:copy="instance.id"
                v-clipboard:error="onError"
                v-clipboard:success="onCopy"
                x-small>content_copy
            </v-icon>
          </p>
          <p class="mb-0 grey--text"><small>{{ instance.name }}</small>
            <v-icon v-clipboard:copy="instance.name"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopy"
                    class="ml-1"
                    x-small>content_copy
            </v-icon>
          </p>
          <p class="mb-0 grey--text"><v-chip x-small>{{ instance.type }}</v-chip></p>
        </v-flex>
        <v-flex md2 sm3 xs3>
          {{ instance.launch_time|formatDate}}
        </v-flex>
        <v-flex md2 sm3 xs3>
          {{ instance.private_ip }}
          <v-icon v-if="instance.private_ip" v-clipboard:copy="instance.private_ip"
                  v-clipboard:error="onError"
                  v-clipboard:success="onCopy"
                  class="mb-1"
                  small>content_copy
          </v-icon>
        </v-flex>
        <v-flex md2 sm3 xs3>
          {{ instance.public_ip | getOrDefault('-') }}
          <v-icon
              v-if="instance.public_ip"
              v-clipboard:copy="instance.public_ip"
              v-clipboard:error="onError"
              v-clipboard:success="onCopy">content_copy
          </v-icon>
        </v-flex>
        <v-flex md2 sm3 xs3>
          <v-chip :class="`instance-state ${instance.state} white--text my-2 caption`" small>
            {{ instance.state }}
          </v-chip>
        </v-flex>
        <v-flex md1 sm4 xs4>
          <v-btn v-if="instance.state == 'running'" @click="stopEc2(instance.id)" class="mr-4" color="primary" small target="_blank">Stop</v-btn>
          <v-btn v-if="instance.state == 'stopped'" @click="startEc2(instance.id)" class="mr-4" color="primary" small target="_blank">Start</v-btn>
          <v-btn v-if="instance.state !== 'stopped' &&  instance.state !== 'running'" class="mr-4" color="primary" small target="_blank" disabled>{{ instance.state }}</v-btn>
        </v-flex>
      </v-layout>
    </div>
    <div v-else class="mt-5 ml-auto mr-auto text-center">
      <p>There are no instances assigned to that environment</p>
    </div>

    <v-snackbar
        v-model="snackbar"
        :color="snackbar_color"
        :value="snackbar"
        bottom
        dark
        outlined
        right
        timeout="1000"
    >
      {{ snackbar_message }}
    </v-snackbar>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";
import getOrDefault from "@/utils/getOrDefault";

export default {
  props: ['env'],
  data: () => {
    return {
      snackbar: false,
      snackbar_color: 'success',
      snackbar_message: '',
      instanceSearch: '',
      instanceShowTerminated: false,
    }
  },
  mounted() {
    this.$store.dispatch('fetchEc2Data', {id: this.env.id})
    this.instanceSearch = '';
    this.instanceShowTerminated = false;

    this.refreshTimer = setInterval(() => {
      this.$store.dispatch('fetchEc2Data', {id: this.env.id})
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer)
  },
  methods: {
    onCopy: function () {
      this.snackbar_message = 'Copied'
      this.snackbar_color = 'success'
      this.snackbar = true
    },
    onError: function (e) {
      this.snackbar_message = 'Failed to copy data'
      this.snackbar_color = 'error'
      this.snackbar = true
      console.log(e)
    },
    startEc2(instanceid){
      this.$store.dispatch('runEc2State', {id: this.env.id, instanceid: instanceid, action: "start"})
      setTimeout(() => this.$store.dispatch('fetchEc2Data', {id: this.env.id}), 2000);
    },
    stopEc2(instanceid){
      this.$store.dispatch('runEc2State', {id: this.env.id, instanceid: instanceid, action: "stop"})
      setTimeout(() => this.$store.dispatch('fetchEc2Data', {id: this.env.id}), 2000);
    },
    filterInstances() {
      let filtered = this.$store.getters.instances(this.env.id).filter((item) => {

        if (item.state === "terminated" && !this.instanceShowTerminated) {
          return false
        }

        if (this.instanceSearch.length === 0) {
          return true
        }

        return item.name.toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
            item.id.toLowerCase().includes(this.instanceSearch.toLowerCase())
      });

      return filtered.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }

        return 0
      })
    },
  },
  filters: {
    formatDate: formatDate,
    getOrDefault: getOrDefault
  }
}
</script>

<style lang="scss">

</style>